<template>
	<Page v-if="sites" :icon="'bi-person-vcard'" :title="'All Sites'" :back="page.back" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<p class="px-3">
					You have {{ sites.length }} sites.
				</p>

				<div v-if="sites" class="row">
					<CardSite
						v-for="(s,index) in sites.sort(createCompareFn('name', 'asc'))" :key="index"
						:site="s.site"
						:estate-id="s.e"
						:client-id="s.c"
					/>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import router from "@/router";

	import { defineComponent, computed, onMounted, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import axios from "@/plugins/axios";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { v4 as uuidv4 } from "uuid";

	import CardSite from "@/views/pages/private/dashboard/partials/CardSite.vue";
	import { createCompareFn } from "@/modules/utils";

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();

	const sites = computed(() => {
		let sites: any;
		sites = [];
		for (let c of authStore.user.clients)
			for (let e of c.estates)
				for (let s of e.sites)
					sites = [...sites, {
						site: s,
						e: e.id,
						c: c.id
					}];
		return sites;
	});

	const page = reactive({
		id: "create_users",
		title: "All Sites",
		back: "/panel/dashboard",
		filters: false,
		breadcrumbs: [
		],
		actions: [
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			break;
		}
	}

</script>
