<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<Page v-if="client && clientId == client.id" :icon="'bi-person-vcard'" :title="client.name || 'Unnamed (please edit client details to add name)'" :back="page.back" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<ModalClientUpdate :element-id="'updateClientModal_'+client.id" :client="client"/>

				<p class="px-3">
					You have {{ client.estates.filter((x: any) => x.deleted_at==null).length }} properties for this client.
				</p>

				<div v-if="client.estates" class="row">
					<CardEstate
						v-for="(estate,) in client.estates.sort(createCompareFn('name', 'asc'))"
						:key="estate.id"
						:estate="estate"
						:client-id="client.id"
					/>
					<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
						<div class="card">
							<div
								class="card-body text-center" type="button" data-bs-toggle="modal" :data-bs-target="'#addEstateModal_'+client.id">
								<div class="media">
									<div class="media-body text-left">
										<h3 ><i class="bi-plus-square" style="font-size: larger;"/></h3>
									</div>
								</div>
							</div>
							<div class="card-footer text-center">
								Add Property
							</div>
						</div>
						<ModalEstateAdd :client="client" :element-id="'addEstateModal_'+client.id"/>
					</div>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import router from "@/router";

	import { defineComponent, computed, onMounted, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import axios from "@/plugins/axios";
	import ModalClientUpdate from "@/views/pages/private/dashboard/partials/ModalClientUpdate.vue";
	import ModalEstateUpdate from "@/views/pages/private/dashboard/partials/ModalEstateUpdate.vue";
	import ModalEstateAdd from "@/views/pages/private/dashboard/partials/ModalEstateAdd.vue";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { v4 as uuidv4 } from "uuid";

	import CardEstate from "@/views/pages/private/dashboard/partials/CardEstate.vue";
	import { createCompareFn } from "@/modules/utils";
	import { useClientStore } from "@/stores/client";
	import type { Client } from "@/types/client";

	const props = defineProps<{
		clientId: string
	}>();

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const clientStore = useClientStore();

	const client = computed<Client | undefined>(() => {
		if (!authStore.user) return undefined;
		return authStore.user.clients.find((x: Client) => x.id == props.clientId);
	});

	const page = reactive({
		id: "create_users",
		title: trans("global.pages.users_create"),
		back: "/panel/projects",
		filters: false,
		breadcrumbs: [
			{
				name: client.value?.name,
				active: true,
				icon: "bi-person-vcard"
			}
		],
		actions: [
			{
				id: "delete",
				name: "Delete Client",
				icon: "bi-trash",
				theme: "none",
				type: "slim-button"
			},
			{
				id: "update",
				name: "Edit Details",
				icon: "bi-pencil",
				theme: "none",
				type: "modal",
				data_bs_target: "#updateClientModal_" + client.value?.id
			}
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			deleteClient();
			break;
		}
	}

	async function deleteClient () {
		if (!client.value) throw new Error("ClientInfo.deleteClient does not have a client to delete");
		if (confirm("Are you sure you want to to delete this client? All linked surveys will be deleted as well.")) {
			try {
				await clientStore.destroy(client.value);
			} finally {
				router.push({ name: "projects" });
			}
		}
	}
</script>
