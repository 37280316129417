<template>
	<Page v-if="surveys" :icon="'bi-person-vcard'" :title="completed == '1' ? 'All Reports' : 'All Assessments'" :back="page.back" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<p class="px-3">
					You have {{ surveys.length }} {{ completed=='1'? "reports" : "unsubmitted assessments" }}.
				</p>

				<div v-if="surveys" class="row">
					<CardSurvey
						v-for="(s,index) in surveys.sort(createCompareFn('name', 'asc'))" :key="index"
						:survey="s.survey"
						:site-id="s.s"
						:estate-id="s.e"
						:client-id="s.c"
					/>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import router from "@/router";

	import { defineComponent, computed, onMounted, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import axios from "@/plugins/axios";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { v4 as uuidv4 } from "uuid";

	import CardSurvey from "@/views/pages/private/dashboard/partials/CardSurvey.vue";
	import { createCompareFn } from "@/modules/utils";
	const props = defineProps<{
		completed: string
	}>();

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();

	const surveys = computed(() => {
		let surveys: any;
		surveys = [];
		for (let c of authStore.user.clients)
			for (let e of c.estates)
				for (let s of e.sites)
					for (let survey of s.woodland_surveys.filter((x:any) => x.complete == (props.completed == "1" ? 1 : 0)))
						surveys = [...surveys, {
							survey: survey,
							s: s.id,
							e: e.id,
							c: c.id
						}];
		return surveys;
	});

	const page = reactive({
		id: "create_users",
		title: "All Assessments",
		back: "/panel/dashboard",
		filters: false,
		breadcrumbs: [
		],
		actions: [
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			break;
		}
	}

</script>
