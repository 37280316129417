<template>
	<Page>
		<template #body>
			<div id="dashboardPage">
				<div class="">
					<section id="dashboard" class="p-3">
						<h1>Dashboard</h1>
					</section>
					<!-- STATS -->
					<section id="minimal-statistics">
						<div class="row p-0">
							<div class="col-xl-3 col-sm-6 col-12 g-4">
								<router-link
									:to="(authStore?.user?.type!='owner') ? '/sites' : '/panel/projects'"
									class="link-underline link-underline-opacity-0">
									<div class="card">
										<div class="card-content">
											<div class="card-body">
												<div class="media d-flex">
													<div class="media-body text-left">
														<h3 class="danger">
															{{ numSites }}
														</h3>
														<span>Sites</span>
													</div>
													<div class="align-self-center">
														<i class="icon-rocket danger font-large-2 float-right" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-xl-3 col-sm-6 col-12 g-4">
								<router-link
									:to="{name:'allSurveys', params:{completed: 0}}"
									class="link-underline link-underline-opacity-0">
									<div class="card">
										<div class="card-content">
											<div class="card-body">
												<div class="media d-flex">
													<div class="media-body text-left">
														<h3 class="success">
															{{ totalUnsubmittedSurveys }}
														</h3>
														<span>Unsubmitted {{ trans('global.what_we_call_whole_thing')
														}}s</span>
													</div>
													<div class="align-self-center">
														<i class="icon-user success font-large-2 float-right" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-xl-3 col-sm-6 col-12 g-4">
								<router-link
									:to="{name:'allSurveys', params:{completed: 1}}"
									class="link-underline link-underline-opacity-0">
									<div class="card">
										<div class="card-content">
											<div class="card-body">
												<div class="media d-flex">
													<div class="media-body text-left">
														<h3 class="success">
															{{ totalReports }}
														</h3>
														<span>Reports</span>
													</div>
													<div class="align-self-center">
														<i class="icon-user success font-large-2 float-right" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</div>
					</section>
					<hr>
					<section v-if="authStore?.user?.type=='admin'" class="px-3">
						<h4>Admin</h4>
						<button type="button" class="btn btn-sm btn-danger mb-2" @click="exportClients()">
							EXPORT DATABASE
						</button>
						<h5>Stats (admin only)</h5>
						<ul>
							<li>Total number of users: {{ authStore.user.total_users }}</li>
							<li>Total number of clients: {{ authStore.user.total_clients }}</li>
							<li>Total number of Properties: {{ authStore.user.total_properties }}</li>
							<li>Total number of Sites: {{ authStore.user.total_sites }}</li>
							<li>
								Total number of <b>incomplete</b> surveys: {{ authStore.user.total_surveys -
									authStore.user.total_surveys_completed }}
							</li>
							<li>
								Total number of surveys <b>completed</b>: {{ authStore.user.total_surveys_completed }}
							</li>
						</ul>
						<button type="button" class="btn btn-sm btn-outline-info mb-2" @click="refresh()">
							Refresh
						</button>
					</section>
					<hr>
					<section class="px-3">
						<h4>Recent {{ trans('global.what_we_call_whole_thing') }}s</h4>
						<div v-for="(recent_survey,index) in recentSurveys" :key="index">
							{{ recent_survey.date.substring(0, 10) }} -
							<router-link
								:to="'/survey/'+recent_survey.id+'/'+recent_survey.site_id+'/'+recent_survey.estate_id+'/'+recent_survey.client_id">
								{{ recent_survey.name }}
							</router-link>
						</div>
					</section>
				</div>
			</div>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import { defineComponent, computed } from "vue";
	import { trans } from "@/helpers/i18n";
	import Page from "@/views/layouts/Page.vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Modal from "@/views/components/Modal.vue";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";

	import "survey-core/modern.min.css";
	import { StylesManager } from "survey-core";
	import WSurvey from "@/views/pages/private/dashboard/surveys/WSurvey.vue";
	import axios from "@/plugins/axios";

	StylesManager.applyTheme("modern");
	import { NVCSurveyJson } from "../../../../Components/NVCSurveyJson";
	import { createCompareFn } from "@/modules/utils";
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();

	const numSites = computed(() => {
		let r = 0;
		if (authStore.user)
			for (let client of authStore.user.clients)
				for (let estate of client.estates)
					r += estate.sites.length;
		return r;
	});
	const totalUnsubmittedSurveys = computed(() => {
		let r = 0;
		if (authStore.user)
			for (let client of authStore.user.clients) {
				for (let estate of client.estates) {
					for (let site of estate.sites) {
						r += site.woodland_surveys ? site.woodland_surveys?.filter((x: any) => x.complete == 0).length : 0;
					}
				}
			}
		return r;
	});
	const totalReports = computed(() => {
		let r = 0;
		if (authStore.user)
			for (let client of authStore.user.clients) {
				for (let estate of client.estates) {
					for (let site of estate.sites) {
						r += site.woodland_surveys ? site.woodland_surveys?.filter((x: any) => x.complete == 1).length : 0;
					}
				}
			}
		return r;
	});
	const recentSurveys = computed(() => {
		let surveys: any [];
		surveys = [];
		if (authStore.user)
			for (let client of authStore.user.clients)
				for (let estate of client.estates)
					for (let site of estate.sites)
						for (let woodland_survey of site.woodland_surveys) {
							surveys = [...surveys, {
								name: woodland_survey.name,
								date: woodland_survey.updated_at,
								client_id: client.id,
								estate_id: estate.id,
								site_id: site.id,
								id: woodland_survey.id
							}];
						}
		return surveys.sort(createCompareFn("date", "desc")).slice(0, 5);
	});

	async function exportClients () {

		// eslint-disable-next-line no-inline-comments
		const url = "/api/clients2/export"; // Replace with your Laravel app URL

		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					"Accept": "application/json"
				}
			});

			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "exported_data.xlsx";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				console.error("Error downloading file:", response.statusText);
			}
		} catch (error:any) {
			console.error("Error:", error.message);
		}

	}

	async function refresh () {
		authStore.syncCurrentUser();

	}

// export default defineComponent({
  // components: {
  //     Page
  // },
  // setup() {
      // return {
        //   trans
      // }
  // }
// });
</script>

