/* eslint-disable no-inline-comments */
import type { WoodlandSurvey } from "@/types/survey";
import { trans } from "@/helpers/i18n";
import { warn } from "vue";

// export function scoreSurvey (survey: WoodlandSurvey): {score: number, advice: any } {
// 	resetSurveyScores(survey);

// 	return { score: scoreSurvey_WCA(survey), advice: scoreSurveyAdvice_m(survey) };

// }

export function scoreSurvey_WCA (survey: WoodlandSurvey) {
	survey.resultWCA =  {
		b: 0,
		c: 0,
		m: 0,
		advice_b: [],
		advice_c: [],
		advice_m: [],
		numQs: 15
	};
	resetSurveyScores(survey);
	try {
		score_q01(survey);
		score_q02(survey);
		score_q03(survey);
		score_q04(survey);
		score_q05(survey);
		score_q06(survey);
		score_q07(survey);
		score_q08(survey);
		score_q09(survey);
		score_q10(survey);
		score_q11(survey);
		score_q12(survey);
		score_q13(survey);
		score_q14(survey);
		score_q15(survey);

		// survey.resultWCA.m = survey.q01.m + survey.q02.m + survey.q03.m + survey.q04.m + survey.q05.m + survey.q06.m + survey.q07.m + survey.q08.m + survey.q09.m + survey.q10.m + survey.q11.m + survey.q12.m + survey.q13.m + survey.q14.m + survey.q15.m;
		survey.resultWCA.b = survey.q01.b + survey.q02.b + survey.q03.b + survey.q04.b + survey.q05.b + survey.q06.b + survey.q07.b + survey.q08.b + survey.q09.b + survey.q10.b + survey.q11.b + survey.q12.b + survey.q13.b + survey.q14.b + survey.q15.b;
		survey.resultWCA.c = survey.q01.c + survey.q02.c + survey.q03.c + survey.q04.c + survey.q05.c + survey.q06.c + survey.q07.c + survey.q08.c + survey.q09.c + survey.q10.c + survey.q11.c + survey.q12.c + survey.q13.c + survey.q14.c + survey.q15.c;
		survey.resultWCA.advice_b = scoreSurveyAdvice_b(survey);
		survey.resultWCA.advice_c = scoreSurveyAdvice_c(survey);
		// survey.resultWCA.advice_m = scoreSurveyAdvice_m(survey);
		survey.resultWCA.numQs = 15;
		return survey.resultWCA;
	}
	catch (error) {
		console.log(error);
		return 0;
	}
}
export function scoreSurvey_BNG (survey: WoodlandSurvey) {
	survey.resultBNG =  {
		b: 0,
		c: 0,
		m: 0,
		advice_b: [],
		advice_c: [],
		advice_m: [],
		numQs: 13
	};
	resetSurveyScores(survey);
	try {
		score_q01(survey);
		score_q02(survey);
		score_q03(survey);
		score_q04(survey);
		score_q05(survey);
		score_q06(survey);
		// score_q07(survey);
		score_q08(survey);
		score_q09(survey);
		score_q10(survey);
		score_q11(survey);
		score_q12(survey);
		score_q13(survey);
		// score_q14(survey);
		score_q15(survey);

		survey.resultBNG.m = survey.q01.m + survey.q02.m + survey.q03.m + survey.q04.m + survey.q05.m + survey.q06.m + /* survey.q07.m +*/ survey.q08.m + survey.q09.m + survey.q10.m + survey.q11.m + survey.q12.m + survey.q13.m + /* survey.q14.m +*/ survey.q15.m;
		survey.resultBNG.advice_m = scoreSurveyAdvice_m(survey);
		survey.resultBNG.numQs = 13;
		return survey.resultBNG;
	}
	catch (error) {
		console.log(error);
		return 0;
	}
}
function resetSurveyScores (survey: WoodlandSurvey) {

	// const S:any = {
	// 	b: 0,
	// 	c: 0,
	// 	m: 0
	// };
	// S["bb"]=5;
	// console.log("S");
	// console.log(S["bb"]);
	survey.q01 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q02 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q03 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q04 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q05 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q06 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q07 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q08 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q09 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q10 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q11 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q12 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q13 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q14 = {
		b: 0,
		c: 0,
		m: 0
	};
	survey.q15 = {
		b: 0,
		c: 0,
		m: 0
	};
	getSurveyParticulars(survey);
}

export function getWoodlandType (survey: WoodlandSurvey) {
	// gets the woodland type from the areas entered in q14
	const question14a = JSON.parse(survey.survey_data)?.question14a ? JSON.parse(survey.survey_data)?.question14a : 0;
	const question14b = JSON.parse(survey.survey_data)?.question14b ? JSON.parse(survey.survey_data)?.question14b : 0;
	const question14c = JSON.parse(survey.survey_data)?.question14c ? JSON.parse(survey.survey_data)?.question14c : 0;
	if (question14a == 0 || (question14b == 0 && question14c == 0))
		return "none";
	if (question14c / question14a > 0.8) return "c";
	else if (question14b / question14a == 1.0) return "b";
	else return "b/c";

}
function getSurveyParticulars (survey: WoodlandSurvey) {
	survey.n_plots_b = 0;
	survey.n_plots_c = 0;
	survey.type = JSON.parse(survey.survey_data).survey_type;
	if (!survey.plot_surveys) return;
	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		if (p.survey_data) {
			const p_survey_data = JSON.parse(p.survey_data);
			if (!(p_survey_data?.q2 === undefined))
				if (p_survey_data?.q1 == "b") {
					survey.n_plots_b++;
				}
				else if (p_survey_data?.q1 == "c") {
					survey.n_plots_c++;
				}
		}
	}
	survey.n_plots_m = survey.plot_surveys.length;
}

function score_q01 (survey: WoodlandSurvey) {

	survey.q01.m = survey.q01.b = survey.q01.c = JSON.parse(survey?.survey_data)?.q1 ? JSON.parse(survey.survey_data).q1 : 0;

}
function score_q02 (survey: WoodlandSurvey) {
	let q2_count_b = 0.0;
	let q2_count_c = 0.0;
	let q2_count_m = 0.0;

	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (!(p_survey_data?.q2 === undefined)) {
			if (p_survey_data?.q1 == "b") {
				q2_count_b += (p_survey_data?.q2 ? 1 : 0);
			}
			else if (p_survey_data?.q1 == "c") {
				q2_count_c += (p_survey_data?.q2 ? 1 : 0);
			}
			q2_count_m += (p_survey_data?.q2 ? 1 : 0);
		}
	}

	const q2_calc_b = (q2_count_b) / (survey.n_plots_b);
	const q2_calc_c = (q2_count_c) / (survey.n_plots_c);
	const q2_calc_m = (q2_count_m) / survey.n_plots_m;

	survey.q02.b = q2_calc_b == 0 ? 3 : (q2_calc_m < 0.4 ? 2 : 1);
	survey.q02.c = q2_calc_c == 0 ? 3 : (q2_calc_m < 0.4 ? 2 : 1);
	survey.q02.m = q2_calc_m == 0 ? 3 : (q2_calc_m < 0.4 ? 2 : 1);
}
function score_q03 (survey: WoodlandSurvey) {
	// 	3. Invasive plant species. From the plot survey results select one below for whole woodland
	// SCORE 3 if number of plots with Cherry Laurel/Rhododendron is zero and the number of plots with any other invasive species is zero
	// SCORE 2 if number of plots with Cherry Laurel/Rhododendron is zero but the number of plots with any other invasive pecies is <10%
	// SCORE 1 if Cherry Laurel/Rhododendron are present and/or invasive species present in ≥10% of plots

	// O = Other
	// LR = Laurel or Rhodedendron
	// ONE SCORE WHOLE WOODLAND
	let q3O_count_m = 0.0;
	let q3LR_count_m = 0.0;

	if (survey.plot_surveys.length == 0)
		return;
	// for each plot, of each type, count the number of times L/R are present, and the total percent cover of
	// other species are present
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (!(p_survey_data?.question15 === undefined)) {
			if (p_survey_data.question15) {

				let totalPercent = 0;
				totalPercent += ((p_survey_data.question3.indexOf("lysichiton_americanus") > -1) && p_survey_data?.question3_1 > 0) ? p_survey_data?.question3_1 : 0;
				totalPercent += ((p_survey_data.question3.indexOf("impatiens_glandulifera") > -1) && p_survey_data?.question3_2 > 0) ? p_survey_data?.question3_2 : 0;
				totalPercent += ((p_survey_data.question3.indexOf("fallopia_japonica") > -1) && p_survey_data?.question3_3 > 0) ? p_survey_data?.question3_3 : 0;
				// totalPercent += p_survey_data?.question3_4 > 0 ? p_survey_data?.question3_4 : 0;// Laurel - dont care about %
				totalPercent += ((p_survey_data.question3.indexOf("gaultheria_shallon") > -1) && p_survey_data?.question3_5 > 0) ? p_survey_data?.question3_5 : 0;
				totalPercent += ((p_survey_data.question3.indexOf("symphoricarpos_albus") > -1) && p_survey_data?.question3_6 > 0) ? p_survey_data?.question3_6 : 0;
				totalPercent += ((p_survey_data.question3.indexOf("lamiastrum_galeobdolon") > -1) && p_survey_data?.question3_7 > 0) ? p_survey_data?.question3_7 : 0;
				// totalPercent += p_survey_data?.question3_8 > 0 ? p_survey_data?.question3_8 : 0;// Rhododendron - dont care about %
				totalPercent += ((p_survey_data.question3.indexOf("other") > -1) && p_survey_data?.question3_9 > 0) ? p_survey_data?.question3_9 : 0;
				q3O_count_m += p_survey_data?.q3O == true ? totalPercent : 0;
				q3LR_count_m += p_survey_data?.q3LR == true ? 1 : 0;

			}
		}
	}
	const q3O_calc_m = q3O_count_m / survey.plot_surveys.length;
	const q3LR_calc_m = q3LR_count_m;

	if (q3O_calc_m == 0 && q3LR_calc_m == 0) survey.q03.m = 3; // none inany plots
	else if (q3O_calc_m < 10 && q3LR_calc_m == 0) survey.q03.m = 2; // <10% "O" and no L/R in any plots
	else survey.q03.m = 1; // >=10% "O" or any L/R in any plots

	if (!(JSON.parse(survey?.survey_data)?.question3b === undefined)) {
		if (JSON.parse(survey?.survey_data).question3b) {
			let no_other_invasives_in_woodland = true;
			if (JSON.parse(survey.survey_data)?.invasives?.length > 0) {
				if ((JSON.parse(survey.survey_data).invasives.indexOf("lysichiton_americanus") > -1) ||
					(JSON.parse(survey.survey_data).invasives.indexOf("impatiens_glandulifera") > -1) ||
					(JSON.parse(survey.survey_data).invasives.indexOf("fallopia_japonica") > -1) ||
					(JSON.parse(survey.survey_data).invasives.indexOf("gaultheria_shallon") > -1) ||
					(JSON.parse(survey.survey_data).invasives.indexOf("symphoricarpos_albus") > -1) ||
					(JSON.parse(survey.survey_data).invasives.indexOf("lamiastrum_galeobdolon") > -1)) {
					no_other_invasives_in_woodland = false;
				}
			}

			// const no_other_invasives_in_woodland = JSON.parse(survey.survey_data).q3O == 0;
			const no_LR_in_woodland = JSON.parse(survey.survey_data).q3LR == 0;
			const no_LR_in_plots = q3LR_calc_m == 0;
			const no_other_invasives_in_plots = q3O_calc_m == 0;
			const proportion_invasives_in_plots_less_than_10pc = q3O_calc_m < 10;
			if (
				no_other_invasives_in_woodland && no_LR_in_woodland &&
				no_other_invasives_in_plots && no_LR_in_plots)
				survey.q03.m = 3;
			else if (proportion_invasives_in_plots_less_than_10pc && no_LR_in_woodland && no_LR_in_plots) survey.q03.m = 2;
			else survey.q03.m = 1;
		}
	}

	survey.q03.b = survey.q03.c = survey.q03.m;
}
function score_q04 (survey: WoodlandSurvey) {
	// Number of native tree and shrub species. From the plot survey results take an average of the percentages entered for plots for woodland type
	// SCORE 3 if the plot average is 5 or more native species
	// SCORE 2 if the plot average is 3–4 native species
	// SCORE 1 if the plot average is 0–2 native species
	let q4_b = 0;
	let q4_c = 0;
	let q4_m = 0;
	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);

		if (p_survey_data?.q1 == "b") {
			if (p_survey_data?.question4)q4_b += p_survey_data?.question4.length;
			// if (p_survey_data?.question9)q4_arr_b = q4_arr_b.concat(p_survey_data?.question9);
		}
		else if (p_survey_data?.q1 == "c") {
			if (p_survey_data?.question4)q4_c += p_survey_data?.question4.length;
			// if (p_survey_data?.question9)q4_arr_c = q4_arr_c.concat(p_survey_data?.question9);
		}

		else {
			if (p_survey_data?.question4)q4_m += p_survey_data?.question4.length;
			// if (p_survey_data?.question9)q4_arr_m = q4_arr_m.concat(p_survey_data?.question9);
		}
	}
	q4_b = survey.n_plots_b > 0 ? q4_b / survey.n_plots_b : 0;
	q4_c = survey.n_plots_c > 0 ? q4_c / survey.n_plots_c : 0;
	q4_m = survey.n_plots_m > 0 ? q4_m / survey.n_plots_m : 0;

	survey.q04.b = (q4_b >= 5) ? 3 : ((q4_b >= 3) ? 2 : 1);
	survey.q04.c = (q4_c >= 5) ? 3 : ((q4_c >= 3) ? 2 : 1);
	survey.q04.m = (q4_m >= 5) ? 3 : ((q4_m >= 3) ? 2 : 1);

}
function score_q05 (survey: WoodlandSurvey) {
	let q5_count_b_canopy = 0;
	let q5_count_c_canopy = 0;
	let q5_count_m_canopy = 0;
	let q5_count_b_understorey = 0;
	let q5_count_c_understorey = 0;
	let q5_count_m_understorey = 0;
	let n_broadleaf_with_understory = 0;
	let n_conifer_with_understory = 0;
	let n_plots_with_understory = 0;

	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (!(p_survey_data?.question5 === undefined)) {
			if (p_survey_data?.q1 == "b") {
				if (p_survey_data?.question5) q5_count_b_canopy += p_survey_data?.question5;
				if (p_survey_data?.question5b) {
					n_broadleaf_with_understory++;
					if (p_survey_data?.question5c) q5_count_b_understorey += p_survey_data?.question5c;
				}
			}
			else if (p_survey_data?.q1 == "c") {
				if (p_survey_data?.question5) q5_count_c_canopy += p_survey_data?.question5;
				if (p_survey_data?.question5b) {
					n_conifer_with_understory++;
					if (p_survey_data?.question5c) q5_count_c_understorey += p_survey_data?.question5c;
				}
			}
			if (p_survey_data?.question5) q5_count_m_canopy += p_survey_data?.question5;
			if (p_survey_data?.question5b) {
				n_plots_with_understory++;
				if (p_survey_data?.question5c) q5_count_m_understorey += p_survey_data?.question5c;
			}
		}
	}

	const q5_calc_b_canopy = survey.n_plots_b > 0 ? (q5_count_b_canopy) / (survey.n_plots_b) : 0;
	const q5_calc_c_canopy = survey.n_plots_c > 0 ? (q5_count_c_canopy) / (survey.n_plots_c) : 0;
	const q5_calc_m_canopy = survey.plot_surveys.length > 0 ? (q5_count_m_canopy) / survey.plot_surveys.length : 0;
	const q5_calc_b_understorey = n_broadleaf_with_understory > 0 ? (q5_count_b_understorey) / n_broadleaf_with_understory : 0;
	const q5_calc_c_understorey = n_conifer_with_understory > 0 ? (q5_count_c_understorey) / n_conifer_with_understory : 0;
	const q5_calc_m_understorey = n_plots_with_understory > 0 ? (q5_count_m_understorey) / n_plots_with_understory : 0;

	// 3 = If upper storey > 80% AND, (there is NO understorey OR understorey >80%)
	// 1 = If upper storey < 50% OR, (there IS understorey AND understorey >80%)
	// 2 = Otherwise
	survey.q05.b = ((q5_calc_b_canopy > 80) && ((n_broadleaf_with_understory == 0) || (q5_calc_b_understorey > 80))) ? 3 :
		(((q5_calc_b_canopy <= 50) || ((n_broadleaf_with_understory > 0) && (q5_calc_b_understorey < 50))) ? 1 : 2);
	survey.q05.c = ((q5_calc_c_canopy > 80) && ((n_conifer_with_understory == 0) || (q5_calc_c_understorey > 80))) ? 3 :
		(((q5_calc_c_canopy <= 50) || ((n_conifer_with_understory > 0) && (q5_calc_c_understorey < 50))) ? 1 : 2);
	survey.q05.m = ((q5_calc_m_canopy > 80) && ((n_plots_with_understory == 0) || (q5_calc_m_understorey > 80))) ? 3 :
		(((q5_calc_m_canopy <= 50) || ((n_plots_with_understory > 0) && (q5_calc_m_understorey < 50))) ? 1 : 2);

}
function score_q06 (survey: WoodlandSurvey) {
	if (JSON.parse(survey?.survey_data)?.question14a < 10 &&
		JSON.parse(survey?.survey_data)?.question6 < 10)
		survey.q06.m = survey.q06.c = survey.q06.b = 3;
	else survey.q06.m = survey.q06.c = survey.q06.b = JSON.parse(survey?.survey_data)?.q6;
}
function score_q07 (survey: WoodlandSurvey) {
	survey.q07.m = survey.q07.c = survey.q07.b = JSON.parse(survey?.survey_data)?.q7;
}
// 8. Woodland Regeeration (plot-level)
function score_q08 (survey: WoodlandSurvey) {
	let q8_broadleaved_calc = 0.0;
	let q8_coniferous_calc = 0.0;
	let q8_mixed_calc = 0.0;
	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (p_survey_data?.q1 == "b") {
			q8_broadleaved_calc = Math.max(q8_broadleaved_calc, +p_survey_data?.q8);
		}
		else if (p_survey_data?.q1 == "c") {
			q8_coniferous_calc = Math.max(q8_coniferous_calc, +p_survey_data?.q8);
		}
		q8_mixed_calc = Math.max(q8_mixed_calc, +p_survey_data?.q8);
	}
	// // broadleaved
	// const q8a = JSON.parse(survey?.survey_data)?.q8a;
	// // coniferous
	// const q8b = JSON.parse(survey?.survey_data)?.q8b;
	survey.q08.b = q8_broadleaved_calc == 0 ? 1 : q8_broadleaved_calc;
	survey.q08.c = q8_coniferous_calc == 0 ? 1 : q8_coniferous_calc;
	survey.q08.m = q8_mixed_calc == 0 ? 1 : q8_mixed_calc;
}
// 9. Tree Health (Whole Woodland)
function score_q09 (survey: WoodlandSurvey) {
	survey.q09.m = survey.q09.b = survey.q09.c = 0;
	let mean_percent_mort_b = 0;
	let mean_percent_mort_c = 0;
	let mean_percent_mort_m = 0;
	let mean_percent_dieback_b = 0;
	let mean_percent_dieback_c = 0;
	let mean_percent_dieback_m = 0;
	let high_risk_present_b = false;
	let high_risk_present_c = false;
	let high_risk_present_m = false;
	let none_present_b = true;
	let none_present_c = true;
	let none_present_m = true;
	let n_valid_plots_b = 0;
	let n_valid_plots_c = 0;
	let n_valid_plots_m = 0;

	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (p_survey_data?.dieback === undefined) p_survey_data.dieback = p_survey_data?.question9a;
		const str_percent_mort = p_survey_data?.question9a;
		const str_percent_dieback = p_survey_data?.dieback;
		const str_score = p_survey_data?.question9b;
		const val_percent_mort: number = +str_percent_mort;
		const val_percent_dieback: number = +str_percent_dieback;
		const val_score: number = +str_score;
		if (!(p_survey_data?.question9a === undefined)) {
			if (p_survey_data?.q1 == "b") {
				mean_percent_mort_b += val_percent_mort;
				mean_percent_dieback_b += val_percent_dieback;
				high_risk_present_b = high_risk_present_b || (val_score == 1);
				none_present_b = none_present_b && (val_score == 3);
				n_valid_plots_b ++;
			}
			else if (p_survey_data?.q1 == "c") {
				mean_percent_mort_c += val_percent_mort;
				mean_percent_dieback_c += val_percent_dieback;
				high_risk_present_c = high_risk_present_c || (val_score == 1);
				none_present_c = none_present_c && (val_score == 3);
				n_valid_plots_c ++;
			}
			{
				mean_percent_mort_m += val_percent_mort;
				mean_percent_dieback_m += val_percent_dieback;
				high_risk_present_m = high_risk_present_m || (val_score == 1);
				none_present_m = none_present_m && (val_score == 3);
				n_valid_plots_m ++;
			}
		}
	}

	if (mean_percent_mort_b > 0 && n_valid_plots_b > 0) mean_percent_mort_b = mean_percent_mort_b / n_valid_plots_b;
	if (mean_percent_mort_c > 0 && n_valid_plots_c > 0) mean_percent_mort_c = mean_percent_mort_c / n_valid_plots_c;
	if (mean_percent_mort_m > 0 && n_valid_plots_m > 0) mean_percent_mort_m = mean_percent_mort_m / n_valid_plots_m;
	if (mean_percent_dieback_b > 0 && n_valid_plots_b > 0) mean_percent_dieback_b = mean_percent_dieback_b / n_valid_plots_b;
	if (mean_percent_dieback_c > 0 && n_valid_plots_c > 0) mean_percent_dieback_c = mean_percent_dieback_c / n_valid_plots_c;
	if (mean_percent_dieback_m > 0 && n_valid_plots_m > 0) mean_percent_dieback_m = mean_percent_dieback_m / n_valid_plots_m;

	// score 3 if mortality average < 10% AND no pest/disease present
	// score 2 if (mortality in [0,25] AND low-risk pest/disease present) OR (mortality in [10,25] AND no pest/disease) (i.e. case 1 or 3 does not apply)
	// score 1 if mortality average > 25% OR high-risk present
	survey.q09.b = (((mean_percent_mort_b < 10) && (mean_percent_dieback_b == 0) && none_present_b) ? 3 : ((mean_percent_mort_b > 25 || high_risk_present_b) ? 1 : 2));
	survey.q09.c = (((mean_percent_mort_c < 10) && (mean_percent_dieback_c == 0) && none_present_c) ? 3 : ((mean_percent_mort_c > 25 || high_risk_present_c) ? 1 : 2));
	survey.q09.m = (((mean_percent_mort_m < 10) && (mean_percent_dieback_m == 0) && none_present_m) ? 3 : ((mean_percent_mort_m > 25 || high_risk_present_m) ? 1 : 2));
}
// 10. Ground vegetation (plot level)
function score_q10 (survey: WoodlandSurvey) {
	// ancient woodland
	let q10a_count_b = 0.0;
	let q10a_count_c = 0.0;
	let q10a_count_m = 0.0;
	// NVC communities
	let q10b_count_b = 0.0;
	let q10b_count_c = 0.0;
	let q10b_count_m = 0.0;
	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (!(p_survey_data?.question10a === undefined) && !(p_survey_data?.question10b === undefined)) {
			if (p_survey_data?.q1 == "b") {
				q10a_count_b += (p_survey_data?.question10a ? 1 : 0);
				q10b_count_b += (p_survey_data?.question10b ? 1 : 0);
			}
			else if (p_survey_data?.q1 == "c") {
				q10a_count_c += (p_survey_data?.question10a ? 1 : 0);
				q10b_count_c += (p_survey_data?.question10b ? 1 : 0);
			}
			{
				q10a_count_m += (p_survey_data?.question10a ? 1 : 0);
				q10b_count_m += (p_survey_data?.question10b ? 1 : 0);
			}
		}
	}
	const q10a_calc_b = (q10a_count_b) / (survey.n_plots_b);
	const q10b_calc_b = (q10b_count_b) / (survey.n_plots_b);

	const q10a_calc_c = (q10a_count_c) / (survey.n_plots_c);
	const q10b_calc_c = (q10b_count_c) / (survey.n_plots_c);

	const q10a_calc_m = (q10a_count_m) / (survey.n_plots_m);
	const q10b_calc_m = (q10b_count_m) / (survey.n_plots_m);

	if (survey.plot_surveys.length) {
		survey.q10.b = (q10b_calc_b < 0.5 ? 1 : (q10a_calc_b < 0.8 ? 2 : 3));
		survey.q10.c = (q10b_calc_c < 0.5 ? 1 : (q10a_calc_c < 0.8 ? 2 : 3));
		survey.q10.m = (q10b_calc_m < 0.5 ? 1 : (q10a_calc_m < 0.8 ? 2 : 3));
	}
	// else survey.q10.b  = survey.q10.c  = survey.q10.m = 0;
	// survey.q10.m = (q10b_calc < 0.8) ? 1 : (q10a_calc < 0.8 ? 2 : 3);
	// survey.q10.m=0;
}
// 11. Woodland Vertical Structure (plot level)
function score_q11 (survey: WoodlandSurvey) {
	let q11_b = 3;
	let q11_c = 3;
	let q11_m = 3;
	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);
		if (!(p_survey_data?.q11 === undefined)) {

			if (p_survey_data?.q1 == "b") {
				q11_b = Math.min(q11_b, p_survey_data?.q11);
			}
			else if (p_survey_data?.q1 == "c") {
				q11_c = Math.min(q11_c, p_survey_data?.q11);
			}
			{
				q11_m = Math.min(q11_m, p_survey_data?.q11);
			}
		}
	}
	survey.q11.b = Math.min(Math.max(q11_b, 1), 3);
	survey.q11.c = Math.min(Math.max(q11_c, 1), 3);
	survey.q11.m = Math.min(Math.max(q11_m, 1), 3);

}
// 12. Veteran trees (whole woodland)
function score_q12 (survey: WoodlandSurvey) {
	if (!(JSON.parse(survey?.survey_data)?.question12 === undefined) && JSON.parse(survey?.survey_data)?.question14a > 0) {
		const trees_per_ha = JSON.parse(survey?.survey_data)?.q12 / JSON.parse(survey?.survey_data)?.question14a;
		survey.q12.m = survey.q12.b = survey.q12.c = trees_per_ha < 1 ? 1 : (trees_per_ha < 2 ? 2 : 3);
	}
	else
		survey.q12.m = survey.q12.b = survey.q12.c = 0;
}
// 13. Amount of deadwood
function score_q13 (survey: WoodlandSurvey) {
	// standing deadwood OR dead branches
	// SCORE 3 if >=50% of all survey plots within the woodland parcel have deadwood, such as standing and fallen deadwood, large dead branches and or stems, branch stubs and stumps, or an abundance of small cavities.
	// SCORE 2 if between 25% and 50% of all survey plots within the woodland parcel have deadwood, such as standing and fallen deadwood, large dead branches and or stems, stubs and stumps, or an abundance of small cavities.
	// SCORE 1 if less than 25% of all survey plots within the woodland parcel have deadwood, such as standing and fallen deadwood, large dead branches and or stems, stubs and stumps, or an abundance of small cavities.
	let q13_calc_m = 0.0;

	if (survey.plot_surveys.length == 0)
		return;
	for (const p of survey.plot_surveys) {
		const p_survey_data = JSON.parse(p.survey_data);

		if (!(p_survey_data?.question13a === undefined) && !(p_survey_data?.question13b === undefined)) {
			q13_calc_m += ((p_survey_data?.question13a || p_survey_data?.question13b) ? 1 : 0);
		}
	}

	// % plots with deadwood
	q13_calc_m = q13_calc_m / survey.plot_surveys.length;

	survey.q13.b = survey.q13.c = survey.q13.m = (q13_calc_m >= 0.5) ? 3 : ((q13_calc_m >= 0.25) ? 2 : 1);

}
// 14. Size of woodlands
function score_q14 (survey: WoodlandSurvey) {
	const M = JSON.parse(survey?.survey_data)?.question14a;
	const B = JSON.parse(survey?.survey_data)?.question14b;
	const C = JSON.parse(survey?.survey_data)?.question14c;
	survey.q14.m = M <= 5 ? 1 : (M <= 20 ? 2 : 3);
	survey.q14.b = B <= 5 ? 1 : (B <= 20 ? 2 : 3);
	survey.q14.c = C <= 5 ? 1 : (C <= 20 ? 2 : 3);
}
function score_q15 (survey: WoodlandSurvey) {
	survey.q15.m = survey.q15.b = survey.q15.c = JSON.parse(survey?.survey_data)?.q15;

}
function condition (score:number) {
	if (score < 15) return "ERROR";
	else if (score < 26) return "POOR";
	else if (score < 36) return "MODERATE";
	else if (score <= 45) return "GOOD";
	else return "ERROR";
}
export function getXLSString (survey: WoodlandSurvey) {
	const is_wca = JSON.parse(survey.survey_data)["survey_type"] == "wca";
	const has_b = JSON.parse(survey.survey_data)["question14b"] > 0;
	const has_c = JSON.parse(survey.survey_data)["question14c"] > 0;
	console.log(JSON.parse(survey.survey_data)["survey_type"]);
	const S = JSON.parse(survey.survey_data)["survey_type"] + "," +
		(is_wca ? "" : trans("global.report.habitat_type_" + (JSON.parse(survey.survey_data)["habitat_type"])) + ",") +
		(is_wca ? "" : (JSON.parse(survey.survey_data)["parcel"] + ",")) +
		(is_wca ? (JSON.parse(survey.survey_data)["question14a"] + ",") : "") +
		(is_wca ? (JSON.parse(survey.survey_data)["question14b"] + ",") : "") +
		(is_wca ? (JSON.parse(survey.survey_data)["question14c"] + ",") : "") +
		(is_wca ? ((has_b ? survey.resultWCA.b : "n/a") + "," +
			(has_c ? survey.resultWCA.c : "n/a") + "," +
			(has_b ? condition(survey.resultWCA.b) : "n/a") + "," +
			(has_c ? condition(survey.resultWCA.c) : "n/a") + ",")
			: (survey.resultBNG.m + "," + condition(survey.resultBNG.m) + ",")) +
		// JSON.parse(survey?.survey_data)?.invasives + "," +
		(is_wca ? (
			survey.q01.b + "," +
								(has_b ? (survey.q02.b + "," +
								survey.q03.b + "," +
								survey.q04.b + "," +
								survey.q05.b + "," +
								survey.q06.b + "," +
								survey.q07.b + "," +
								survey.q08.b + "," +
								survey.q09.b + "," +
								survey.q10.b + "," +
								survey.q11.b + "," +
								survey.q12.b + "," +
								survey.q13.b + "," +
								survey.q14.b + "," +
								survey.q15.b + ",") : "n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,") +
								(has_c ? (survey.q02.c + "," +
								survey.q02.c + "," +
								survey.q03.c + "," +
								survey.q04.c + "," +
								survey.q05.c + "," +
								survey.q06.c + "," +
								survey.q07.c + "," +
								survey.q08.c + "," +
								survey.q09.c + "," +
								survey.q10.c + "," +
								survey.q11.c + "," +
								survey.q12.c + "," +
								survey.q13.c + "," +
								survey.q14.c + "," +
								survey.q15.c) : "n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,n/a,"))
			: (
				survey.q01.m + "," +
								survey.q02.m + "," +
								survey.q03.m + "," +
								survey.q04.m + "," +
								survey.q05.m + "," +
								survey.q06.m + "," +
								// survey.q07.m + "," +
								survey.q08.m + "," +
								survey.q09.m + "," +
								survey.q10.m + "," +
								survey.q11.m + "," +
								survey.q12.m + "," +
								survey.q13.m + "," +
								// survey.q14.m + "," +
								survey.q15.m)
		);
	console.log(S);
	return S;
}

export function scoreSurveyAdvice_c (survey: WoodlandSurvey) {
	const SSA_C:Array<any> = [];
	SSA_C.push([trans("wca.questions.question1a"), survey.q01, survey.q01.c < 3 ? "Only " + survey.q01.c + " age class" + (survey.q01.c > 1 ? "es" : "") + " are present in your conifer woodland, woodland with a wider age distribution of trees will score higher" : "Your conifer woodland has a wide range of age classes."]);
	SSA_C.push([trans("wca.questions.question2b"), survey.q02, survey.q02.c < 3 ? (survey.q02.c == 2 ? "Evidence of significant browsing pressure is present in 40% or less of conifer woodland. Try to reduce this to improve your score here." : "Evidence of significant browsing pressure is present in 40% or more of conifer woodland. Try to reduce this to improve your score here.") : "No significant browsing damage evident in your conifer woodland."]);
	SSA_C.push([trans("wca.questions.question3c"), survey.q03, survey.q03.c < 3 ? (survey.q03.c == 2 ? "Rhododendron and Cherry Laurel are not present, and other invasive species were noted in the woodland walk, and/or occupy less than 10% cover of plots" : "Rhododendron and/or Cherry Laurel are present, or other invasive species occupy more than 10% cover") : "No invasive species present in conifer woodland"]);
	SSA_C.push([trans("wca.questions.question4d"), survey.q04, survey.q04.c < 3 ? (survey.q04.c == 2 ? "Three to four native tree or shrub species found across conifer woodland. Woodland with a greater number of native tree or shrub species will score higher." : "Two or fewer native tree or shrub species across conifer woodland. Woodland with a greater number of native tree or shrub species will score higher.") : "Five or more native tree or shrub species found across conifer woodland"]);
	SSA_C.push([trans("wca.questions.question5e"), survey.q05, survey.q05.c == 3 ? "More than 80% of canopy trees and more than 80% of understorey shrubs are native in conifer woodland." : (survey.q05.c == 2 ? "50-80% of canopy trees and 50-80% of understorey shrubs are native in conifer woodland." : "Less than 50% of canopy trees and <50% of understorey shrubs are native in conifer woodland.")]);
	SSA_C.push([trans("wca.questions.question6f"), survey.q06, survey.q06.c == 3 ? "10 to 20%  of woodland has areas of temporary open space (or your woodland area is <10ha and has <10% open space)." : (survey.q06.c == 2 ? "20 to 40% of conifer woodland has areas of temporary open space." : "More than 40%  or less than 10% of conifer woodland has areas of temporary open space.")]);
	if (survey.type == "wca") SSA_C.push([trans("wca.questions.question7x"), survey.q07, survey.q07.c == 3 ? ">20%" : (survey.q07.c == 2 ? "10-20%" : "<10%")]);
	SSA_C.push([trans("wca.questions.question8g"), survey.q08, survey.q08.c == 3 ? "All three classes present in conifer woodland; Trees 4-7cm dbh, saplings and seedlings or coppice regrowth" : (survey.q08.c == 2 ? "One or two age classes only present in conifer woodland." : "No age classes or coppice regrowth present in conifer woodland.")]);
	SSA_C.push([trans("wca.questions.question9h"), survey.q09, survey.q09.c == 3 ? "Tree mortality is less than 10%, no pests or diseases and no crown dieback" : (survey.q09.c == 2 ? "10-25% mortality, crown dieback present, or low risk pest or disease present" : "Greater than 25% tree rapid mortality and/or any high risk pest or disease present")]);
	SSA_C.push([trans("wca.questions.question10i"), survey.q10, survey.q10.c == 3 ? "Recognisable woodland NVC plant community at ground layer present, strongly characterised by ancient woodland flora specialists" : (survey.q10.c == 2 ? "Recognisable woodland NVC plant community at ground layer present" : "No recognisable woodland NVC plant community at ground layer.")]);
	SSA_C.push([trans("wca.questions.question11j"), survey.q11, survey.q11.c == 3 ? "Three or more storeys across all survey plots or a complex woodland." : (survey.q11.c == 2 ? "2 storeys across all survey plots." : "One or fewer storeys across all survey plots")]);
	SSA_C.push([trans("wca.questions.question12k"), survey.q12, survey.q12.c == 3 ? "Two or more veteran trees per hectare." : (survey.q12.c == 2 ? "One to two veteran trees per hectare." : "Less than one veteran tree per hectare present in conifer woodland ")]);
	SSA_C.push([trans("wca.questions.question13l"), survey.q13, survey.q13.c == 3 ? "50% or more of all survey plots within the woodland have deadwood, such as standing and fallen deadwood, large dead branches and or stems, branch stubs and stumps, or an abundance of small cavities" : (survey.q13.c == 2 ? "Between 25% and 50% of woodland and/or between 25% and 50% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps." : "Less than 25%  of woodland and/or less than  25% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps")]);
	if (survey.type == "wca") SSA_C.push([trans("wca.questions.question14x"), survey.q14, survey.q14.c == 3 ? "Greater than 20 hectares." : (survey.q14.c == 2 ? "Between 5 and 20 hectares." : "Less than 5 hectares.")]);
	SSA_C.push([trans("wca.questions.question15m"), survey.q15, survey.q15.c == 3 ? "No nutrient enrichment or damaged ground evident" : (survey.q15.c == 2 ? "Less than 1 ha in total of nutrient enrichment across woodland area and/or less than 20% of woodland area has damaged ground" : "More than 1 ha of nutrient enrichment and/or more than 20% of conifer woodland area has damaged ground.")]);
	return SSA_C;
}

export function scoreSurveyAdvice_b (survey: WoodlandSurvey) {
	const SSA_B:Array<any> = [];
	SSA_B.push([trans("wca.questions.question1a"), survey.q01, survey.q01.b < 3 ? "Only " + survey.q01.b + " age class" + (survey.q01.c > 1 ? "es" : "") + " are present in your broadleaf woodland, woodland with a wider age distribution of trees will score higher" : "Your broadleaf woodland has a wide range of age classes."]);
	SSA_B.push([trans("wca.questions.question2b"), survey.q02, survey.q02.b < 3 ? (survey.q02.b == 2 ? "Evidence of significant browsing pressure is present in 40% or less of broadleaf woodland. Try to reduce this to improve your score here." : "Evidence of significant browsing pressure is present in 40% or more of broadleaf woodland. Try to reduce this to improve your score here.") : "No significant browsing damage evident in your broadleaf woodland."]);
	SSA_B.push([trans("wca.questions.question3c"), survey.q03, survey.q03.b < 3 ? (survey.q03.b == 2 ? "Rhododendron and Cherry Laurel are not present, and other invasive species were noted in the woodland walk, and/or occupy less than 10% cover of plots" : "Rhododendron and/or Cherry Laurel are present, or other invasive species occupy more than 10% cover") : "No invasive species present in broadleaf woodland"]);
	SSA_B.push([trans("wca.questions.question4d"), survey.q04, survey.q04.b < 3 ? (survey.q04.b == 2 ? "Three to four native tree or shrub species found across broadleaf woodland. Woodland with a greater number of native tree or shrub species will score higher." : "Two or fewer native tree or shrub species across broadleaf woodland. Woodland with a greater number of native tree or shrub species will score higher.") : "Five or more native tree or shrub species found across broadleaf woodland"]);
	SSA_B.push([trans("wca.questions.question5e"), survey.q05, survey.q05.b == 3 ? "More than 80% of canopy trees and more than 80% of understorey shrubs are native in broadleaf woodland." : (survey.q05.b == 2 ? "50-80% of canopy trees and 50-80% of understorey shrubs are native in broadleaf woodland." : "Less than 50% of canopy trees and <50% of understorey shrubs are native in broadleaf woodland.")]);
	SSA_B.push([trans("wca.questions.question6f"), survey.q06, survey.q06.b == 3 ? "10 to 20%  of woodland has areas of temporary open space (or your woodland area is <10ha and has <10% open space)." : (survey.q06.b == 2 ? "20 to 40% of broadleaf woodland has areas of temporary open space." : "More than 40%  or less than 10% of broadleaf woodland has areas of temporary open space.")]);
	if (survey.type == "wca") SSA_B.push([trans("wca.questions.question7x"), survey.q07, survey.q07.b == 3 ? ">20%" : (survey.q07.b == 2 ? "10-20%" : "<10%")]);
	SSA_B.push([trans("wca.questions.question8g"), survey.q08, survey.q08.b == 3 ? "All three classes present in broadleaf woodland; Trees 4-7cm dbh, saplings and seedlings or coppice regrowth" : (survey.q08.b == 2 ? "One or two age classes only present in broadleaf woodland." : "No age classes or coppice regrowth present in broadleaf woodland.")]);
	SSA_B.push([trans("wca.questions.question9h"), survey.q09, survey.q09.b == 3 ? "Tree mortality is less than 10%, no pests or diseases and no crown dieback" : (survey.q09.b == 2 ? "10-25% mortality, crown dieback present, or low risk pest or disease present" : "Greater than 25% tree rapid mortality and/or any high risk pest or disease present")]);
	SSA_B.push([trans("wca.questions.question10i"), survey.q10, survey.q10.b == 3 ? "Recognisable woodland NVC plant community at ground layer present, strongly characterised by ancient woodland flora specialists" : (survey.q10.b == 2 ? "Recognisable woodland NVC plant community at ground layer present" : "No recognisable woodland NVC plant community at ground layer.")]);
	SSA_B.push([trans("wca.questions.question11j"), survey.q11, survey.q11.b == 3 ? "Three or more storeys across all survey plots or a complex woodland." : (survey.q11.b == 2 ? "2 storeys across all survey plots." : "One or fewer storeys across all survey plots")]);
	SSA_B.push([trans("wca.questions.question12k"), survey.q12, survey.q12.b == 3 ? "Two or more veteran trees per hectare." : (survey.q12.b == 2 ? "One to two veteran trees per hectare." : "Less than one veteran tree per hectare present in broadleaf woodland ")]);
	SSA_B.push([trans("wca.questions.question13l"), survey.q13, survey.q13.b == 3 ? "50% or more of all survey plots within the woodland have deadwood, such as standing and fallen deadwood, large dead branches and or stems, branch stubs and stumps, or an abundance of small cavities" : (survey.q13.b == 2 ? "Between 25% and 50% of woodland and/or between 25% and 50% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps." : "Less than 25%  of woodland and/or less than  25% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps")]);
	if (survey.type == "wca") SSA_B.push([trans("wca.questions.question14x"), survey.q14, survey.q14.b == 3 ? "Greater than 20 hectares." : (survey.q14.b == 2 ? "Between 5 and 20 hectares." : "Less than 5 hectares.")]);
	SSA_B.push([trans("wca.questions.question15m"), survey.q15, survey.q15.b == 3 ? "No nutrient enrichment or damaged ground evident" : (survey.q15.b == 2 ? "Less than 1 ha in total of nutrient enrichment across woodland area and/or less than 20% of woodland area has damaged ground" : "More than 1 ha of nutrient enrichment and/or more than 20% of broadleaf woodland area has damaged ground.")]);
	return SSA_B;
}

export function scoreSurveyAdvice_m (survey: WoodlandSurvey) {
	const SSA_M:Array<any> = [];
	SSA_M.push([trans("wca.questions.question1a"), survey.q01, survey.q01.m < 3 ? "Only " + survey.q01.m + " age class" + (survey.q01.c > 1 ? "es" : "") + " are present in your woodland, woodland with a wider age distribution of trees will score higher" : "Your woodland has a wide range of age classes."]);
	SSA_M.push([trans("wca.questions.question2b"), survey.q02, survey.q02.m < 3 ? (survey.q02.m == 2 ? "Evidence of significant browsing pressure is present in 40% or less of woodland parcel. Try to reduce this to improve your score here." : "Evidence of significant browsing pressure is present in 40% or more of woodland parcel. Try to reduce this to improve your score here.") : "No significant browsing damage evident in your woodland."]);
	SSA_M.push([trans("wca.questions.question3c"), survey.q03, survey.q03.m < 3 ? (survey.q03.m == 2 ? "Rhododendron and Cherry Laurel are not present, and other invasive species were noted in the woodland walk, and/or occupy less than 10% cover of plots" : "Rhododendron and/or Cherry Laurel are present, or other invasive species occupy more than 10% cover") : "No invasive species present in woodland"]);
	SSA_M.push([trans("wca.questions.question4d"), survey.q04, survey.q04.m < 3 ? (survey.q04.m == 2 ? "Three to four native tree or shrub species found across woodland parcel. Woodland with a greater number of native tree or shrub species will score higher." : "Two or fewer native tree or shrub species across woodland parcel. Woodland with a greater number of native tree or shrub species will score higher.") : "Five or more native tree or shrub species found across woodland parcel"]);
	SSA_M.push([trans("wca.questions.question5e"), survey.q05, survey.q05.m == 3 ? "More than 80% of canopy trees and more than 80% of understorey shrubs are native in the the woodland parcel." : (survey.q05.m == 2 ? "50-80% of canopy trees and 50-80% of understorey shrubs are native in the woodland parcel." : "Less than 50% of canopy trees and <50% of understorey shrubs are native in the woodland parcel.")]);
	SSA_M.push([trans("wca.questions.question6f"), survey.q06, survey.q06.m == 3 ? "10 to 20%  of woodland has areas of temporary open space (or your woodland area is <10ha and has <10% open space)." : (survey.q06.m == 2 ? "20 to 40 %  of woodland has areas of temporary open space." : "More than 40%  or less than 10% of woodland has areas of temporary open space.")]);

	SSA_M.push([trans("wca.questions.question8g"), survey.q08, survey.q08.m == 3 ? "All three age classes present in woodland; Trees 4-7cm dbh, saplings and seedlings or coppice regrowth" : (survey.q08.m == 2 ? "One or two age classes only present in woodland or woodland parcel." : "No age classes or coppice regrowth present in woodland.")]);
	SSA_M.push([trans("wca.questions.question9h"), survey.q09, survey.q09.m == 3 ? "Tree mortality is less than 10%, no pests or diseases and no crown dieback" : (survey.q09.m == 2 ? "10-25% mortality, crown dieback present, or low risk pest or disease present" : "Greater than 25% tree rapid mortality and/or any high risk pest or disease present")]);
	SSA_M.push([trans("wca.questions.question10i"), survey.q10, survey.q10.m == 3 ? "Recognisable woodland NVC plant community at ground layer present, strongly characterised by ancient woodland flora specialists" : (survey.q10.m == 2 ? "Recognisable woodland NVC plant community at ground layer present" : "No recognisable woodland NVC plant community at ground layer.")]);
	SSA_M.push([trans("wca.questions.question11j"), survey.q11, survey.q11.m == 3 ? "Three or more storeys across all survey plots or a complex woodland." : (survey.q11.m == 2 ? "2 storeys across all survey plots." : "One or fewer storeys across all survey plots")]);
	SSA_M.push([trans("wca.questions.question12k"), survey.q12, survey.q12.m == 3 ? "Two or more veteran trees per hectare." : (survey.q12.m == 2 ? "One to two veteran trees per hectare." : "Less than one veteran tree per hectare present in woodland ")]);
	SSA_M.push([trans("wca.questions.question13l"), survey.q13, survey.q13.m == 3 ? "50% or more of all survey plots within the woodland parcel have deadwood, such as standing and fallen deadwood, large dead branches and or stems, branch stubs and stumps, or an abundance of small cavities" : (survey.q13.m == 2 ? "Between 25% and 50% of woodland and/or between 25% and 50% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps." : "Less than 25%  of woodland and/or less than  25% of all survey plots surveyed have standing deadwood, large dead branches/stems and stumps")]);

	SSA_M.push([trans("wca.questions.question15m"), survey.q15, survey.q15.m == 3 ? "No nutrient enrichment or damaged ground evident" : (survey.q15.m == 2 ? "Less than 1 ha in total of nutrient enrichment across woodland area and/or less than 20% of woodland area has damaged ground" : "More than 1 ha of nutrient enrichment and/or more than 20% of woodland area has damaged ground.")]);
	return SSA_M;
}

export function getWoodlandTypeWarnings (survey: WoodlandSurvey) {
	const warnings = [];
	if (JSON.parse(survey.survey_data).survey_type == "wca") {
		const is_conifer = JSON.parse(survey.survey_data).question14c;
		const is_broadle = JSON.parse(survey.survey_data).question14b;
		const prop_conifer = is_conifer / JSON.parse(survey.survey_data).question14a;
		const prop_broadle = is_broadle / JSON.parse(survey.survey_data).question14a;
		const c_plots_needed = (JSON.parse(survey.survey_data).question14a > 30 ? 10 : 5) * prop_conifer;
		const b_plots_needed = (JSON.parse(survey.survey_data).question14a > 30 ? 10 : 5) * prop_broadle;
		const percent_conifer_plots = 100 * survey.n_plots_c / survey.n_plots_m;
		const percent_broadle_plots = 100 * survey.n_plots_b / survey.n_plots_m;
		survey.n_plots_b;

		// if proportion of CONIFER plots is over/under by more than 1 plot
		if (Math.abs(Math.round(c_plots_needed) - Math.round(survey.n_plots_c)) >= 1)
			warnings.push("You have reported " +
				toNumberNoneOrAll(Math.round(100 * prop_conifer), "no") +
				" conifer woodland but  " +
				toNumberNoneOrAll(Math.round(percent_conifer_plots), "none") +
				" of your plots have been recorded as being coniferous. Is this correct?");
		// if proportion of BROADLEAVED plots is over/under by more than 1 plot
		if (Math.abs(Math.round(b_plots_needed) - Math.round(survey.n_plots_b)) >= 1)
			warnings.push("You have reported " +
				toNumberNoneOrAll(Math.round(100 * prop_broadle), "no") +
				" broadleaf woodland but  " + toNumberNoneOrAll(Math.round(percent_broadle_plots), "none") +
				" of your plots have been recorded as being broadleaved. Is this correct?");

		if (JSON.parse(survey?.survey_data)?.question14b + JSON.parse(survey?.survey_data)?.question14c != JSON.parse(survey?.survey_data)?.question14a)
			warnings.push("Broadleaved/mixed and conifer areas do not add up to total woodland ares. Adjust in woodland survey.");
	}
	return warnings;
}
function toNumberNoneOrAll (input: number, forNone: string) {
	if (input == 0) return forNone;
	else if (input == 100) return "all";
	else return String(input) + "%";
}
export function getWarnings (survey: WoodlandSurvey|null) {
	let warnings: any[];
	warnings = [];
	if (survey && getWoodlandTypeWarnings(survey).length) warnings = [...warnings, ...getWoodlandTypeWarnings(survey)];

	return warnings;
}
export function getErrors (survey: WoodlandSurvey|null) {

	const errors:any = [];
	if (!survey) return errors;
	if (survey?.plot_surveys.length == 0)
		errors.push("You have not completed any plot surveys.");
	let min_plots = 5;
	if (!(JSON.parse(survey?.survey_data)?.question14a === undefined)) {
		if (JSON.parse(survey?.survey_data)?.question14a > 30) min_plots = 10;
	}
	if (survey.plot_surveys.length < min_plots)
		errors.push("Fewer then " + min_plots + " plots surveyed. This is the minimum number of plots required for woodlands " + (min_plots == 5 ? "smaller than" : "larger than") + " 30ha");

	return errors;
}
