<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<Page
		v-if="estate && site && siteId == site.id"
		:icon="'bi-pin-map'"
		:title="site.name || 'Unnamed (please edit woodland site details to add name)'"
		:back="page.back"
		:breadcrumbs="page.breadcrumbs"
		:actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<ModalSiteUpdate :element-id="'updateSiteModal_'+site.id" :site="site"/>
				<p class="px-3">
					You have {{ site.woodland_surveys.length }} assessments for this site.
				</p>
				<div v-if="site.woodland_surveys" class="row">
					<CardSurvey
						v-for="(survey,index) in site.woodland_surveys.sort(createCompareFn('assessment_date', 'asc'))" :key="index"
						:survey="survey"
						:site-id="site.id"
						:estate-id="estate.id"
						:client-id="clientId"
					/>

					<!-- Button trigger modal: ADD SITE -->
					<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
						<div class="card">
							<div
								class="card-body text-center" type="button" data-bs-toggle="modal" :data-bs-target="'#addSurveyModal_'+site.id">
								<div class="media">
									<div class="media-body text-left">
										<h3 ><i class="bi-clipboard-plus" style="font-size: larger;"/></h3>
									</div>
								</div>
							</div>
							<div class="card-footer text-center">
								Add {{ trans('global.what_we_call_whole_thing') }}
							</div>
						</div>
						<ModalSurveyAdd :site="site" :element-id="'addSurveyModal_'+site.id" :estate-id="estateId" :client-id="clientId"/>
					</div>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import { useRouter } from "vue-router";
	import { computed, reactive } from "vue";
	import ModalSiteUpdate from "@/views/pages/private/dashboard/partials/ModalSiteUpdate.vue";
	import ModalSurveyAdd from "@/views/pages/private/dashboard/partials/ModalSurveyAdd.vue";
	import { useAuthStore } from "@/stores/auth";
	import CardSurvey from "@/views/pages/private/dashboard/partials/CardSurvey.vue";
	import { createCompareFn } from "@/modules/utils";
	import { useSiteStore } from "@/stores/site";
	import type { Client } from "@/types/client";
	import type { Estate } from "@/types/estate";
	import type { Site } from "@/types/site";
	import { trans } from "@/helpers/i18n";

	const props = defineProps<{
		siteId: string,
		estateId: string,
		clientId: string
	}>();

	const authStore = useAuthStore();
	const siteStore = useSiteStore();
	const router = useRouter();

	const client = computed<Client | undefined>(() => {
		if (!authStore.user) return undefined;
		return authStore.user.clients.find((x: any) => x.id == props.clientId);
	});
	const estate = computed<Estate | undefined>(() => {
		if (!client.value) return undefined;
		return client.value.estates.find((x: any) => x.id == props.estateId);
	});
	const site = computed<Site | undefined>(() => {
		if (!estate.value) return undefined;
		return estate.value.sites.find((x: any) => x.id == props.siteId);
	});

	const page = reactive({
		id: "site_info_" + site.value?.id,
		title: site.value?.name,
		back: "/estate/" + estate.value?.id + "/" + client.value?.id,
		filters: false,
		breadcrumbs: authStore.user.type != "owner" ? [
			{
				name: client.value?.name,
				to: "/client/" + client.value?.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.value?.name,
				to: "/estate/" + estate.value?.id + "/" + client.value?.id,
				icon: "bi-map"

			},
			{
				name: site.value?.name,
				active: true,
				icon: "bi-pin-map"
			}
		] : [
			{
				name: site.value?.name,
				active: true,
				icon: "bi-pin-map"
			}],
		actions: [
			{
				id: "delete",
				name: "Delete Site",
				icon: "bi-trash",
				theme: "none",
				type: "slim-button"
			},
			{
				id: "update",
				name: "Edit Details",
				icon: "bi-pencil",
				theme: "none",
				type: "modal",
				data_bs_target: "#updateSiteModal_" + site.value?.id
			}
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			deleteSite();
			break;
		}
	}

	async function deleteSite () {
		if (!site.value) throw new Error("SiteInfo.deleteSite does not have a site to delete");
		if (!estate.value) throw new Error("SiteInfo.deleteSite does not have an associated estate");
		if (!client.value) throw new Error("SiteInfo.deleteDite does not have an associated site");
		if (confirm("Are you sure you want to to delete this site? All linked surveys will be deleted as well.")) {
			try {
				await siteStore.destroy(site.value);
			} finally {
				router.push({
					name: "estateInfo",
					params: { estateId: estate.value.id, clientId: client.value.id }
				});
			}
		}
	}
</script>
