<template>
	<Page
		:icon="(authStore?.user?.type!='owner') ? 'bi-person-vcard' : 'bi-pin-map'"
		:title="(authStore?.user?.type!='owner') ? 'All Clients' : 'All Sites'"
		:back="page.back"
		:breadcrumbs="page.breadcrumbs"
		:actions="page.actions">
		<template #body>
			<div id="projectsPage">
				<div class="grey-bg container-fluid">
					<!-- AGENT -->
					<!-- @todo SHould this be == 'agent' ? -->
					<div v-if="(authStore?.user?.type!='owner')">
						<section id="clients">
							<!-- <h4 class="text-uppercase">
								Clients
							</h4> -->
							<p class="px-3">
								You have {{ authStore?.user?.clients ? authStore?.user?.clients.filter((x: any) => x.deleted_at==null).length : 0 }} clients.
							</p>

							<div v-if="authStore?.user?.clients" class="row">
								<CardClient v-for="(client,index) in authStore.user.clients.sort(createCompareFn('name', 'asc'))" :key="index" :client="client"/>

								<!-- Button trigger modal: ADD CLIENT -->
								<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
									<div class="card">
										<div
											class="card-body text-center" type="button" data-bs-toggle="modal" data-bs-target="#addClientModal">
											<div class="media">
												<div class="media-body text-left">
													<h3 ><i class="bi-plus-square" style="font-size: larger;"/></h3>
												</div>
											</div>
										</div>
										<div class="card-footer text-center">
											Add Client
										</div>
									</div>
									<ModalClientAdd :element-id="'addClientModal'" />
								</div>
							</div>
						</section>
					</div>

					<!-- OWNER -->
					<div v-else-if="authStore.user.type=='owner'">
						<section id="sites">
							<!-- <h4 class="text-uppercase">
								Sites
							</h4> -->
							<p class="px-3">
								You have {{ allSites.length }} sites.
							</p>

							<div class="row">
								<!--
									@todo allSites is an array of [{site: Site, e: string, c: string}] so does not have a name property to
									sort by (it needs to be sorted by site.name). So this sort possibly does not work. But as above, this
									block is not shown on screen so cannot verify this.
								-->
								<CardSite
									v-for="(site,index) in allSites.sort(createCompareFn('name', 'asc'))" :key="index"
									:site="site.site"
									:estate-id="site.e"
									:client-id="site.c"
								/>

								<!-- Button trigger modal: ADD SITE -->
								<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
									<div class="card">
										<div
											class="card-body text-center" type="button" data-bs-toggle="modal" :data-bs-target="'#addSiteModal_'+authStore?.user?.clients[0]?.estates[0].id">
											<div class="media">
												<div class="media-body text-left">
													<h3 ><i class="bi-plus-square" style="font-size: larger;"/></h3>
												</div>
											</div>
										</div>
										<div class="card-footer text-center">
											Add Woodland Site
										</div>
									</div>
									<ModalSiteAdd :estate="null" :element-id="'addSiteModal_'+authStore?.user?.clients[0]?.estates[0].id"/>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import { defineComponent, computed, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import Page from "@/views/layouts/Page.vue";

	import { useAuthStore } from "@/stores/auth";

	// import "survey-core/defaultV2.min.css";
	// import { StylesManager } from "survey-core";

	// StylesManager.applyTheme("defaultV2");

	const authStore = useAuthStore();
	import ModalClientAdd from "@/views/pages/private/dashboard/partials/ModalClientAdd.vue";
	import ModalClientUpdate from "@/views/pages/private/dashboard/partials/ModalClientUpdate.vue";
	import CardClient from "@/views/pages/private/dashboard/partials/CardClient.vue";
	import CardSite from "@/views/pages/private/dashboard/partials/CardSite.vue";
	import ModalSiteAdd from "@/views/pages/private/dashboard/partials/ModalSiteAdd.vue";
	import ModalSiteUpdate from "@/views/pages/private/dashboard/partials/ModalSiteUpdate.vue";

	import axios from "@/plugins/axios";
	import { v4 as uuidv4 } from "uuid";
	import { useSurveyStore } from "@/stores/survey";

	import { createCompareFn } from "@/modules/utils";

	const surveyStore = useSurveyStore();

	import AuthService from "@/services/AuthService";
	const authService = new AuthService();

	const clientsList = computed(() => {
		let r = "";
		let i = 0;
		for (let c in authStore.user.clients) {
			r += " collapseExample_" + i;
			i++;
		}
		return r;
	});

	// @todo
	// This can be typed using an interface:
	// interface SiteInfo = {
	//     site: Site
	//     e: string
	//     c: string
	// }
	// and then allSites can be typed as:
	// computed<SiteInfo[]>
	// This typing then throws up the issue with the sorting described above.
	const allSites = computed(() => {
		let sites: any;
		sites = [];
		for (let c of authStore.user.clients)
			for (let e of c.estates)
				for (let s of e.sites)
					sites = [...sites, {
						site: s,
						e: e.id,
						c: c.id
					}];
		return sites;
	});

	const page = reactive({
		id: "user_projects",
		back: "/panel/dashboard",
		filters: false,
		breadcrumbs: [],
		actions: []
	});

</script>

<style>
.inactive{
    background-color:rgb(var(--wca-deselected-card));
}
.active{
	background-color:rgb(var(--wca-selected-card));
	border:solid;
	border-width:1px;
	border-color:rgb(var(--wca-deselected-card));
}

.list-group > .list-group {
  display: none;
  margin-bottom: 0;
}
.list-group-item:focus-within + .list-group {
  display: block;
}

.list-group > .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group > .list-group-item:first-child {
  border-top-width: 0;
}

.list-group  > .list-group > .list-group-item {
  padding-left: 2.5rem;
}

    /* .list-group-item {
      position: relative;
    } */

    .chevron {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      transition: transform 0.3s ease;
    }
    .chevron2 {
      position: absolute;
      top: 20px;
      left: 5px;
      transform: translateY(-50%);
      transition: transform 0.3s ease;
    }

.collapsed .chevron  {
  transform: translateY(-50%) rotate(-90deg);
}

.collapsed .chevron2  {
  transform: translateY(-50%) rotate(-90deg);
}

.wca-list {
	padding-right:0!important;
}
  </style>
