<template>
	<h2 :id="'heading_'+index" class="accordion-header">
		<button class="accordion-button" :class="props.collapsed ? 'collapsed' : ''" type="button" data-bs-toggle="collapse" aria-expanded="true" :data-bs-target="'#collapse_'+activeSurvey.id" :aria-controls="'collapse_'+activeSurvey.id">
			<span
				v-if="!goToError"
				:class="props.collapsed ? 'collapsed' : ''">
				<!-- style="color: rgb(var(--bs-success-rgb));"> -->
				<i class="bi-check-square me-2" /></span>
			<span v-else :class="props.collapsed ? 'collapsed' : ''">
				<!-- :style="'color: rgb( var(--bs-danger-rgb));'"> -->
				<i class="bi-x-square me-2" /></span>
			Plot {{ index + 1 }} {{ JSON.parse(activeSurvey.survey_data).location_descriptor ? " - " + JSON.parse(activeSurvey.survey_data).location_descriptor : "" }}
			<span class=" font-weight-light fst-italic small">&nbsp;- Last synced: {{ activeSurvey.synced_at ? new Date(activeSurvey.synced_at)?.toLocaleString() : "" }}</span>
		</button>
	</h2>
	<div v-show="(activeSurvey?.deleted==null)" :id="'collapse_'+activeSurvey.id" class="accordion-collapse p-0" :class="props.collapsed ? 'collapse' : ''" :aria-labelledby="'heading_'+activeSurvey.id" data-bs-parent="#plotAccordion">
		<div class="accordion-body p-0">
			<a class="m-2 btn btn-warning" type="button" @click.prevent="deletePlot(activeSurvey)">
				Delete Plot<i class="bi-trash ms-2" />
			</a>
			<br>
			<slot/>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { watch, computed } from "vue";

	/** WCA Root Component */
	import * as SurveyCore from "survey-core";
	// @ts-ignore
	import { nouislider } from "surveyjs-widgets";
	import "nouislider/dist/nouislider.css";
	nouislider(SurveyCore);

	import Inputmask from "inputmask";
	// @ts-ignore
	import { inputmask } from "surveyjs-widgets";
	inputmask(SurveyCore);

	import "survey-core/defaultV2.min.css";
	import { StylesManager } from "survey-core";
	import { usePlotSurveyStore } from "@/stores/plot";
	import { Model } from "survey-core";

	StylesManager.applyTheme("bootstrap");

	const plotSurveyStore = usePlotSurveyStore();

	const props = defineProps<{
		surveyJson: any;
		parentSurvey: any;
		activeSurvey: any | null;
		index: number;
		collapsed: boolean | null;
		surveyData: any;
	}>();

	async function deletePlot (plot_survey:any) {

		if (confirm("Are you sure you want to to delete this plot?")) {
			plotSurveyStore.destroy(props.activeSurvey);
		}
	}

	const survey = new Model(props.surveyJson);
	function setSurveyData (plotSurvey: any) {
		if (plotSurvey == null) return;
		// Restore survey results
		const prevData = plotSurvey;

		if (prevData) {
			const data = prevData;
			survey.data = data;
			if (data.pageNo) {
				survey.currentPageNo = data.pageNo;
			}
		}
	}
	const goToError = computed(() => {
		setSurveyData(props.surveyData);
		let hasError = false;
		let currentPage = null;
		// const __survey = new Model(props.surveyJson);
		// console.log(props.surveyJson);
		// console.log(survey.pages);
		let X = null;
		survey.pages.forEach(page => {
			if (!hasError) {
				if (page.visible && !(page.name == "all")) page.questions.forEach(question => {
					// console.log(question.isVisible);
					if (question.isVisible && question.isRequired &&
						(question.isEmpty() || question.hasErrors()) &&
						(question.renderAs != "image")) {
						hasError = true;
						currentPage = page;
						// Navigate to the first page with an error

						// console.log("currentPage.name ("+props.index+"): " + currentPage.name);
						// if (page.name == "all") return null;
						// console.log(page);
						X =  currentPage.title;
					}
				});
			}
		});
		// console.log(X);
		return X;
	});

</script>

<style>

.noUi-origin{
	width:10%;
}
</style>
