<template>
	<Page :class="'fade-in mt-3'">
		<template #body>
			<div class="p-0">
				<div class="p-3">
					<h1>Help with your Woodland Condition Assessment</h1>
					<br>

					<p>
						How valuable is your woodland for wildlife? The Woodland Condition Assessment (WCA) will help
						you assess your woodland's condition in a standardised way. Carrying out the assessment is
						straightforward,
						and anyone can do it. You do not need any special equipment. It involves doing a walking survey
						through the
						wood, using a paper form or using the free Woodland Condition app (which works offline) to
						record features on
						the way. You can then compare your results to the national thresholds (Good, Moderate and Poor).
						This will give
						you an overview of the condition of your woodland's habitats and identify any issues you may
						need to address.
					</p>
					<p>
						Paper forms for the Woodland Condition Assessment can be found <a
							href="https://sylva.org.uk/wp-content/uploads/2024/05/WCA5.pdf"
							target="_blank">here</a>.<br>
						Paper forms for the Woodland Condition Assessment for staturory biodiversity metric can be found
						<a href="https://sylva.org.uk/wp-content/uploads/2024/05/WCA6.pdf" target="_blank">here</a>.
					</p>
					<p>
						Help can be accessed by clicking at the top right of each assessment section.
						<!-- todo: Can we add a thumbnail of what this looks like.  -->
						Those confident in woodland indicators/species, will be able to carry out an
						assessment just using the App and Woodland Condition Assessment guide.
					</p>

					<p>
						<!-- (click through to theguide). -->
						For those wanting additional support Field Studies Council (FSC) have produced four helpful
						guides
						and also run Woodland Condition Assessment training.
						<!-- todo: click through to FSC landing page (to be created by FSC). -->
					</p>
					<p>
						Click on the topics below to get further help.
					</p>
				</div>

				<div id="accordionHelp" class="accordion">
					<div class="accordion-item">
						<h2 id="headingOne" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								How to carry out a Woodland Condition Assessment
							</button>
						</h2>
						<div
							id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								There are three stages to the Woodland Condition Assessment (see below for further
								information on each stage):
								<ul>
									<li>
										Stage 1: Planning the survey: desk-based - plan a walking route through the
										woodland using maps and aerial photographs
									</li>
									<li>Stage 2: 10 metre radius survey plots</li>
									<li>Stage 3: Whole woodland survey</li>
								</ul>
								<p>
									Carry out the WCA twice in the same year
									<ul>
										<li>Early spring (or winter) - before ground vegetation obscures views</li>
										<li>Summer - when trees are in leaf and ground vegetation is present</li>
									</ul>
								</p>
								<p>
									If only one assessment is being undertaken, this should be carried out in
									spring/summer.
								</p>
								<a
									href="https://sylva.org.uk/wp-content/uploads/2024/05/WCA5.pdf"
									target="_blank">The Woodland Condition Assessment</a> and
								<a
									href="https://sylva.org.uk/wp-content/uploads/2024/05/WCA6.pdf"
									target="_blank">The Woodland Condition Assessment for
									statutory biodiversity metric</a> guides provide more information.
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="headingTwo" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
								Woodland Condition Assessment for Biodiversity Metric
							</button>
						</h2>
						<div
							id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								For detailed guidance please download the guide for <a
									href="https://sylva.org.uk/wp-content/uploads/2024/05/WCA6.pdf"
									target="_blank">Woodland Condition Assessment for Biodiversity Metric</a>
								The Field Studies Council also provide an <a
									href="https://www.field-studies-council.org/" target="_blank">online training
									course</a>.
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_install" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_install" aria-expanded="false"
								aria-controls="collapse_install">
								How to install the Woodland Condition Assessment application
							</button>
						</h2>
						<div
							id="collapse_install" class="accordion-collapse collapse"
							aria-labelledby="heading_install" data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									You can use the woodland condition assessment as a mobile app on your
									iPhone/iPad
									and/or Android phone as well as your desktop or laptop.
								</p>
								<h4>Android</h4>
								<ol>
									<li>
										Open Chrome web browser on your android phone and visit <a
											href="https://woodlandcondition.sylva.org.uk">https://woodlandcondition.sylva.org.uk</a>.
									</li>
									<li>
										You should see a pop-up inviting you to install the app. If this does not
										appear, click the options button in the browser and click 'Install App'.<br>
										<img
											class="my-2 me-2" width="100%" style="max-width:300px"
											src="/help/android_1.jpg"><img
												class="my-2" width="100%"
												style="max-width:300px" src="/help/android_2.jpg">
									</li>
									<li>
										Step 2. should also work in FireFox and Brave browsers, although the install
										pop-up may not show in these browsers.
									</li>
								</ol>
								<h4>iPhone/iPad</h4>
								<ol>
									<li class="my-2">
										Please open <b>Safari</b> browser on your iPhone/iPad and visit <a
											href="https://woodlandcondition.sylva.org.uk">https://woodlandcondition.sylva.org.uk</a>.
										Then follow the steps below.
									</li>
									<li class="my-2">
										Click on the share button shown in the below image. On iPad, you will see
										the same icon at the
										top-right of the browser.<br>
										<img class="my-2" width="100%" style="max-width:300px" src="/help/1.jpg">
									</li>
									<li class="my-2">
										On the next dialog box, click on 'Add to Home Screen'.<br>
										<img class="my-2" width="100%" style="max-width:300px" src="/help/2.jpg">
									</li>
									<li class="my-2">
										Click on 'Add' at the top right of your screen.<br>
										<img class="my-2" width="100%" style="max-width:300px" src="/help/3.jpg">
									</li>
									<li class="my-2">
										Look for the WCA app icon on your iPhone/iPad screen.
										Any updates we make to the WCA app are immediately available to you in
										real-time.
									</li>
								</ol>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_4a" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_4a" aria-expanded="false" aria-controls="collapse_4a">
								Allowing automatic GPS collection
							</button>
						</h2>
						<div
							id="collapse_4a" class="accordion-collapse collapse" aria-labelledby="heading_4a"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									Access to geolocation data is required by the app when adding the geolocation to
									your plot surveys automatically. We strongly
									recommend that you allow the app access to geolocation data as this is more
									straightforward than entering either
									a precise grid reference or what-three-words location.
								</p>
								<p>
									Depending on your device and settings, GPS positioning may be recorded
									automatically. If no data shows on the plot survey page, you will need to use another means of
									collectiong the GPS location of your plots and enter it manually for each
									plot {{ trans("global.what_we_call_plots_and_walk") }}.
								</p>

								<p>
									iOS: If you have previously disabled location services and with to re-enable
									location sharing, please follow these steps. You will need to change
									settings in two places, as follows:
								</p>
								<ol>
									<li>Open the <strong>Settings</strong> app on your iPhone.</li>
									<li>
										Go to <strong>Privacy & Security</strong> > <strong>Location Services</strong>.
									</li>
									<li>Find and tap on <strong>Safari Websites</strong>.</li>
									<li>
										Set location access to <strong>While Using the App</strong> or
										<strong>Always</strong>.
									</li>
									<li>
										Finally, you will also need to manually allow location access for the
										Safari app: go to Settings > Safari > Location and set it to "Ask" or
										"Allow".
									</li>
								</ol>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_4b" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_4b" aria-expanded="false" aria-controls="collapse_4b">
								Activating offline mode
							</button>
						</h2>
						<div
							id="collapse_4b" class="accordion-collapse collapse" aria-labelledby="heading_4b"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									Poor data coverage or low network speeds can be detrimental to the application's performance.
									If this is the case, use the offline functionality by selecting the 'Online'/'Working offline' slider at the top of the page.
									This avoids needing to use your device in airplane mode, whilst taking the application fully offline.
									When your data connection is restored, change the slider back to online in order to sync your data with the online site and view using your desktop or laptop browser.
									This will not affect GPS reception within the app, or any functionality outside of the app.
								</p>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_4" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_4" aria-expanded="false" aria-controls="collapse_4">
								Your dashboard
							</button>
						</h2>
						<div
							id="collapse_4" class="accordion-collapse collapse" aria-labelledby="heading_4"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									On logging in to your account, you will be taken to your dashboard. You
									can use
									the menu links (left-hand side if on web or tablet, bottom on mobile) or
									dashboard buttons to navigate the app.
								</p>
								<p>
									Your dashboard shows a summary of your sites, unfinished assessments,
									and
									submitted reports as well as the most recent (if any) assessments that
									you have
									worked on.
								</p>
								<ul>
									<li>
										Web and tablet view:<br>
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/dashboard_web.png">
									</li>
									<li>
										Mobile view:<br>
										<img
											class="my-2" width="100%" style="max-width:300px"
											src="/help/dashboard_mobile.png">
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_5" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_5" aria-expanded="false" aria-controls="collapse_5">
								How assessments are structured
							</button>
						</h2>
						<div
							id="collapse_5" class="accordion-collapse collapse" aria-labelledby="heading_5"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									Each assessment that you carry out must be associated with a woodland
									site.
								</p>
								<p v-if="authStore?.user?.type!='agent'">
									<span v-if="authStore?.user?.type=='owner'">As you have registered as a
										woodland
										owner</span>
									<span v-else>For woodland owners</span>,
									this will be the top of your data hierarchy{{
										authStore?.user?.type=="owner" ?
											':' : '.' }}
								</p>
								<p v-if="authStore?.user?.type!='owner'">
									For forest agents working with multiple clients,
									<span v-if="authStore?.user?.type!='agent'">your data hierarchy will be
										more
										extensive. </span><span v-else>y</span>Your
									data is first organised by client. A client may then have multiple
									properties.
									Each property may then have multiple woodland sites.
									You will need to create this hierarchy of data before starting a new
									assessment:
								</p>
								<p>
									<ul>
										<li v-if="authStore?.user?.type!='owner'">
											To add a new client, first navigate to the clients page by clicking
											the link
											in the menu (left-hand side
											if on web or tablet, bottom on mobile). Existing clients will show
											on this
											page. Click on 'Add client' button to create your client, enter a
											name and
											click 'Add'.
											<br>
											<img
												class="my-2 me-2" width="100%" style="max-width:600px"
												src="/help/help_clients_web.png">
											<img
												class="my-2" width="100%" style="max-width:300px"
												src="/help/help_clients_mobile.png">
										</li>
										<li v-if="authStore?.user?.type!='owner'">
											Next, click on the client that you have just created to go to the
											properties
											for this client. You can create a property for this client
											similarly. Click
											'Add property', enter the property name, and click 'Add'.
											<br>
											<img
												class="my-2 me-2" width="100%" style="max-width:600px"
												src="/help/help_property.png">
											<img
												class="my-2" width="100%" style="max-width:600px"
												src="/help/help_add_property.png">
										</li>
										<li v-if="authStore?.user?.type!='owner'">
											Next, click on the newly created property to go to the sites for
											this
											property. You can create a site for this client similarly. Click
											'Add site',
											enter the site name, grid reference (at the center of the site),
											county
											location, and click 'Add'.
											<br><br>You may optionally also enter the Single Business Identifier
											(SBI),
											Higher Tier Reference, or Woodland Management Plan number if you
											have these
											(they may be particularly useful and/or necessary for Countryside
											Stewardship programs or if you are completing the woodland condition
											assessment for statutory biodiversity metric).
											<br>
											<img
												class="my-2 me-2" width="100%" style="max-width:600px"
												src="/help/help_sites.png">
											<img
												class="my-2" width="100%" style="max-width:600px"
												src="/help/help_add_site.png">
										</li>
										<li v-else>
											Navigate to your sites by clicking 'Sites' on the menu (left-hand
											side for
											web and tablets, bottom for mobile). Next, click 'Add site', enter
											the site
											name, grid reference (at the center of the site), county location,
											and click
											'Add'.
											<br><br>You may optionally also enter the Single Business Identifier
											(SBI),
											Higher Tier Reference, or Woodland Management Plan number if you
											have these
											(they may be particularly useful and/or necessary for Countryside
											Stewardship programs or if you are completing the woodland condition
											assessment for statutory biodiversity metric).
											<br>
											<img
												class="my-2 me-2" width="100%" style="max-width:600px"
												src="/help/help_sites.png">
											<img
												class="my-2" width="100%" style="max-width:600px"
												src="/help/help_add_site.png">
										</li>
										<li>
											Click on the new site to navigate to surveys for this woodland site
											and
											follow the instructions below
										</li>.
									</ul>
								</p>
								<p>
									<span v-if="authStore?.user?.type!='owner'">Clients, properties and
										s</span><span v-else>S</span>ites can be edited by clicking the
									pencil icon
									beneath their name, they can be deleted using the trash can icon.
								</p>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_6" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_6" aria-expanded="false" aria-controls="collapse_6">
								Creating, editing and deleting an assessment
							</button>
						</h2>
						<div
							id="collapse_6" class="accordion-collapse collapse" aria-labelledby="heading_6"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<ul>
									<li>Navigate to the site to which you wish to add a new assessment.</li>
									<li>
										You can edit assessment details or delete an existing assessment by
										clicking
										the pencil icon or trash can beneath the assessment name.
									</li>
									<li>
										To create a new assessment, click the 'Add assessment' button and
										enter
										details about the assessment. Use this form to select the type of
										assessment
										that you will be carrying out,
										the name of the assessor, and date of the assessment. You can also
										set your
										privacy setting for this assessment here.
										Once you click 'Add' you will be taken to the start of the
										desk-based survey
										for this assessment (keep reading the sections below for more
										information on
										the three survey stages for conducting the assessment).
										<br><img
											class="my-2 me-2" width="100%" style="max-width:600px"
											src="/help/help_surveys.png">
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/help_add_survey.png">
									</li>
									<li>
										To modify the surveys for an existing assessment, click on the
										assessment
										name to be taken to the assessment overview. Here you will see
										further
										details
										about the selected assessment, including provisional assessment
										scores. You
										can submit an assessment from this assessment overview page, and
										(once
										submitted) download the assessment report.
										<br>
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/help_survey_overveiw.png">
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_9" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_9" aria-expanded="false" aria-controls="collapse_9">
								Entering data for your assessment
							</button>
						</h2>
						<div
							id="collapse_9" class="accordion-collapse collapse" aria-labelledby="heading_9"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<ul>
									<li>
										If you are returning to an existing assessment, navigate to the
										assessment
										overview page and click the button at the bottom of the screen to
										start or
										update the assessment.
										<br>
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/help_survey_overveiw.png">
									</li>
									<li>
										Survey questions are labelled numberically for a Woodland Condition
										Assessment, and by letters for a Woodland Condition Assessment for
										statutory
										biodiversity metric.
										The assessment is broken down into three stages, as detailed below.
										The
										first question to answer as part of the desk-based stage is question
										6|F.
										<br>
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/help_start_survey.png">
									</li>
									<li>
										You can navigate between the three stages as you go along using the
										tabs at
										the top of the survey. All answers are saved when the next button is
										clicked
										and it is possible to complete the woodland walk questions and plot
										survey
										questions simultaneously byt switching between them,
									</li>
									<li>
										The application is designed to work offline and we strongly
										recommend
										installing the application using the steps above for best
										functionality.
										Offline data will be syncronised with the server when the device
										regains a
										network ocnnection and the data viewable on another device such as
										your
										desktop or laptop thereafter.
										If you are entering data on more than one device, it is recommended
										to use
										the refresh button located in the profile menu as you go along.
									</li>
									<li>
										In order to accurately track the GPS location and automatically
										enter this
										for new plots, you may need to have a data connection on the device
										you are
										using. As a fallback, this data can be entered manually.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_7" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_7" aria-expanded="false" aria-controls="collapse_7">
								Creating, editing and deleting a plot survey
							</button>
						</h2>
						<div
							id="collapse_7" class="accordion-collapse collapse" aria-labelledby="heading_7"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<ul>
									<li>
										Once you have entered an assessment, you can add plot surveys to the
										assessment by navigating to the 'Stage 2: Pot surveys' tab and
										clicking the
										'New plot' button.
										<br>
										<img
											class="my-2" width="100%" style="max-width:600px"
											src="/help/help_plots.png">
									</li>
									<li>
										You can see all of your plot surveys here as well as guidance on the
										number
										of plots recommended (as a minimum) for your assessment.
										you can delete plots by opening the plot and then clicking the trash
										can
										icon.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_10" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_10" aria-expanded="false" aria-controls="collapse_10">
								Repeating plots for a new assessment
							</button>
						</h2>
						<div
							id="collapse_10" class="accordion-collapse collapse" aria-labelledby="heading_10"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								Plot locations can be repeated for a subsequent survey. When adding the new
								survey,
								select which survey for this site you wish to repeat the plot locations
								from.
								<br>
								<img
									class="my-2" width="100%" style="max-width:600px"
									src="/help/help_repeat_plots.png">
							</div>
						</div>
					</div>
					<!-- <div class="accordion-item">
							<h2 id="heading_11a" class="accordion-header">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_11a" aria-expanded="false" aria-controls="collapse_11a">
									Deleting a plot
								</button>
							</h2>
							<div id="collapse_11a" class="accordion-collapse collapse" aria-labelledby="heading_11a" data-bs-parent="#accordionHelp">
								<div class="accordion-body">
									Deleting a plot
								</div>
							</div>
						</div> -->
					<!-- <div class="accordion-item">
							<h2 id="heading_11" class="accordion-header">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_11" aria-expanded="false" aria-controls="collapse_11">
									Deleting a woodland
								</button>
							</h2>
							<div id="collapse_11" class="accordion-collapse collapse" aria-labelledby="heading_11" data-bs-parent="#accordionHelp">
								<div class="accordion-body">
									Deleting a woodland
								</div>
							</div>
						</div> -->
					<div class="accordion-item">
						<h2 id="heading_12" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_12" aria-expanded="false" aria-controls="collapse_12">
								Planning the survey: desk-based
							</button>
						</h2>
						<div
							id="collapse_12" class="accordion-collapse collapse" aria-labelledby="heading_12"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									The route for the walk and location of the 10m radius survey plots
									should be
									planned in advance,
									with assessments in each woodland habitat type. Woodland size, % open
									ground and
									% favourable
									land cover should be calculated before you start fieldwork.
								</p>
								<p>
									You can use a map and/or aerial photographs of the woodland, but
									also knowledge of the two different main woodland habitat types that
									may be present. Search online for the <a
										href="https://www.forestresearch.gov.uk/tools-and-resources/national-forest-inventory/"
										target="_blank">National Forest Inventory</a>.
								</p>
								<p>
									The route should pass through all the woodland type(s) present.
									Although you can use paths for this part of the survey, do not restrict
									your route to paths (especially well-worn paths). Try to follow your
									planned route as close as possible.
								</p>
								<p>
									Plan the location of stopping points where you will carry out the more
									detailed
									plot surveys. In small woods and/or woods of
									unchanging woodland type and age classes, a minimum of five temporary
									survey
									plots is required. For larger woodlands (e.g.
									30 hectares or more) or where there is variation in the two main
									woodland
									habitat types and age classes, a minimum of 10
									survey plots is recommended.
								</p>
								<p>
									The locations for the 10m survey plots should represent variation and
									the
									woodland type(s) present. For example, in a
									woodland with young and mature age classes of broadleaved and coniferous
									woodland, survey plots should be located in at
									least one young and one mature section of both the broadleaved and
									coniferous
									woodland (i.e. 4 survey plots).
								</p>
								<p>
									If you have no pre-existing knowledge of the woodland, a quick walk
									through the
									woodland should quickly give a feel for the
									amount of variation that is likely to be encountered and the temporary
									survey
									plot locations can then be marked accordingly
									on a printed woodland survey map in advance of the survey.
								</p>
								<img src="/wca5.jpg" style="max-width:300px" class="mx-auto d-block">
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_13" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_13" aria-expanded="false" aria-controls="collapse_13">
								10 metre radius survey plots
							</button>
						</h2>
						<div
							id="collapse_13" class="accordion-collapse collapse" aria-labelledby="heading_13"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									10m radius survey plots should be planned in advance and must represent
									the
									woodland type(s)
									present. In small woods and/or woods of unchanging woodland type and age
									classes, a minimum of
									five temporary survey plots are required. For larger woodlands (30
									hectares or
									more) or where there
									is variation in the two main woodland habitat types and age classes, a
									minimum
									of 10 survey plots
									are recommended.
								</p>
								<p>
									To carry out a plot survey, place an object on the ground to mark its
									centre.
									Then measure 10
									metres in four directions. It is important to stay within the 10m plot
									boundaries.
								</p>
								<p>
									Survey the plots first before completing the whole woodland survey form.
								</p>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_14" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_14" aria-expanded="false" aria-controls="collapse_14">
								Whole Woodland Survey
							</button>
						</h2>
						<div
							id="collapse_14" class="accordion-collapse collapse" aria-labelledby="heading_14"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									Whole woodland survey is carried out via a walk through the woodland.
									The route
									should be
									planned in advance. Assessments are made by main woodland habitat types;
								</p>
								<p>
									<ul>
										<li>Broadleaved, mixed and yew woodland – 20% or more broadleaves</li>
										<li>Coniferous woodland – 80% or more conifer</li>
									</ul>
								</p>
								<p>
									You can use a map and/or aerial photographs of the woodland, to identify
									which
									different woodland
									habitat types that may be present. Also search online for the <a
										href="https://www.forestresearch.gov.uk/tools-and-resources/national-forest-inventory"
										target="_blank">National Forest Inventory</a>.
								</p>
								<p>
									The route should pass through all the woodland type(s) present. Although
									you can
									use paths for this
									part of the survey, do not restrict your route to paths (especially
									well-worn
									paths). Try to follow your
									planned route as close as possible.
								</p>
								<p>
									Survey the plots first before completing the whole woodland survey form.
								</p>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_15" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_15" aria-expanded="false" aria-controls="collapse_15">
								What equipment do you need to carry out an assessment
							</button>
						</h2>
						<div
							id="collapse_15" class="accordion-collapse collapse" aria-labelledby="heading_15"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								<p>
									You do not need any special equipment other than a 10m tape measure. The
									assessment involves
									doing a walking survey through the wood using the free Woodland
									Condition
									Assessment app (which, once you have installed and registered, works
									offline) to record features.
								</p>

								It is recommended, however, to take the following:
								<ul>
									<li>
										Site map with locations of survey plots marked (this may be a
										digital map on
										a device),
									</li>
									<li>tape measure (at least 10m),</li>
									<li>DBH measuring tape,</li>
									<li>
										GPS handheld device (this may be the same device as that with the
										app).
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="accordion-item">
						<h2 id="heading_16" class="accordion-header">
							<button
								class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
								data-bs-target="#collapse_16" aria-expanded="false" aria-controls="collapse_16">
								Feedback on the app
							</button>
						</h2>
						<div
							id="collapse_16" class="accordion-collapse collapse" aria-labelledby="heading_16"
							data-bs-parent="#accordionHelp">
							<div class="accordion-body">
								If you have any feedback on the app, please send us an <a
									href="mailto:feedback-woodlandcondition@sylva.org.uk">email</a>.
							</div>
						</div>
					</div>
				</div>

				<br>
				<p>
					Woodland owners are encouraged as part of their efforts to improve the ecological
					condition of
					their woodlands to record:
				</p>
				<ol>
					<li>
						Any tree health issues that are apparent through the <a
							href="https://www.observatree.org.uk" target="_blank">Observatree scheme</a>
					</li>
					<li>
						The presence of any veteran trees as part of the Woodland Trust's <a
							href="https://ati.woodlandtrust.org.uk/" target="_blank">register of ancient
							trees</a>
					</li>
				</ol>

				<p class="text-center py-3">
					<router-link to="/panel/dashboard">
						<UiButton :theme="'theme'" label="Head to your dashboard to begin" />
					</router-link>
				</p>
			</div>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	// import { defineComponent } from "vue";
	import Page from "@/views/layouts/Page.vue";

	import UiButton from "@/views/components/input/UiButton.vue";
	import { ref } from "vue";
	import { trans } from "@/helpers/i18n";

	import { useAuthStore } from "@/stores/auth";
	const authStore = useAuthStore();

	const isChecked = ref(true);

	function toggleSwitch () {
		// isChecked = !isChecked;
		console.log(isChecked.value);
	}
	function fadeOut () {
		// Set isVisible to false to trigger the fade out effect
		isChecked.value = false;
	}

	function focusInput (id: string) {
		const element = document.getElementById(id);
		console.log(element?.offsetTop);
		if (element) {
			window.scrollTo({
				top: element.offsetTop,
				behavior: "smooth"
			});
		}
	}
</script>
<style>
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.25s;
}
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* input[type="checkbox"] {
  background: rgb(var(--fsc-ocean-blue));
  color: white;
} */
/* input[type="checkbox"]:checked {
  background: rgb(var(--fsc-sunshine-yellow));
  color: white;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  color: white;
}

input[type="checkbox"]:after {
  content: ' ';
  position: relative;
  left: 40%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
} */
</style>
