<template>
	<div v-if="authStore.user && authStore.user.id" class="bg-light d-flex">
		<PwaStatus />
		<ModalSyncError element-id="modal-sync-error" />

		<!--SIDEBAR - only shows when wide enough-->

		<div class="container-fluid overflow-hidden ">
			<div class="row vh-100 overflow-x-hidden overflow-y-hidden">
				<div class="col-12 col-sm-3 col-xl-2  px-0 tobs5-bg-theme-600 d-flex sticky-top overflow-visible">
					<div
						class="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start p-2 text-white w-100">
						<router-link
							v-slot="{ navigate }" to="/about"
							class=" w-100 border-0 text-white link-underline link-underline-opacity-0">
							<div class="d-flex align-items-center pb-2 mb-2" @click="navigate">
								<!-- <img src="/favicon.svg" :alt="state.app.name" width="75" height="75"> -->
								<h3>
									Woodland
									Condition
									Assessment
									<span style="color:red">{{ appEdition }}</span>
								</h3>
							</div>
						</router-link>
						<ul
							id="menu"
							class="border-top w-100 d-none d-sm-flex px-sm-2 nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start">
							<br>
							<WcaMenu :state="state" :type="'desktop'" />
						</ul>
						<ul
							id="menu"
							class="d-sm-none px-sm-2 nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start">
							<WcaMenu :state="state" :type="'mobile'" />
						</ul>
						<div
							class="dropdown  mt-sm-auto ms-auto ms-sm-0 flex-shrink-1 sm-bg-theme-800 py-3 my-p-0 w-sm-100">
							<a
								id="dropdownUser1" data-test-id="dropdownUser1" href="#"
								class="px-2 d-flex align-items-center text-white text-decoration-none dropdown-toggle"
								data-bs-toggle="dropdown" aria-expanded="false"
								@click="state.isAccountDropdownOpen = !state.isAccountDropdownOpen">
								<i class="bi bi-circle-fill" :class="(pwaStore.onlineAndConnected && pwaStore.manuallyOnline) ?'online':'offline'" />

								<span class="d-none d-sm-inline mx-1 d-inline-block text-truncate">{{
									authStore.user.full_name }}</span>

								<span v-if="pwaStore.event || pwaStore.showRefresh" class="badge bg-secondary">{{
									(pwaStore.event?1:0) + (pwaStore.showRefresh?1:0) }}</span>
							</a>
							<ul class="dropdown-menu  text-small shadow" aria-labelledby="dropdownUser1">
								<li class="dropdown-item">
									<router-link
										to="/panel/profile"
										class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80">
										{{ trans('global.pages.profile') }}
									</router-link>
								</li>
								<li class="dropdown-item">
									<a
										href="#"
										class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
										data-test-id="logout-link" @click.prevent="onLogout">
										{{ trans('global.phrases.sign_out') }}
									</a>
								</li>
								<li>
									<hr class="dropdown-divider">
								</li>
								<li class="dropdown-item">
									<a
										class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
										:class="!(pwaStore.onlineAndConnected && pwaStore.manuallyOnline) ? 'disabled' :''"
										@click="sync">
										Manually sync data
									</a>
								</li>
								<li>
									<hr class="dropdown-divider">
								</li>
								<li class="dropdown-item">
									<div v-if="pwaStore.showRefresh">
										New version available:
										<button @click="pwaStore.updateSW(true)">
											Update
											<span
												class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
												<span class="visually-hidden">New alerts</span>
											</span>
										</button>
									</div>
									<div v-else-if="pwaStore.event">
										<button
											class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
											@click="install">
											Install App<span class="badge bg-danger" />
											<!-- <span class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
												<span class="visually-hidden">New alerts</span>
											</span> -->
										</button>
									</div>
									<!-- <div v-else class="dropdown-item disabled">
										<a class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80">
											Latest version (or cannot update)
										</a>
									</div> -->
									<div v-else>
										<button
											class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
											@click="router.push('/help')">
											Download Instructions
											<!-- <span class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
												<span class="visually-hidden">New alerts</span>
											</span> -->
										</button>
									</div>
								</li>
								<li>
									<hr class="dropdown-divider pb-0">
								</li>
								<li class="dropdown-item disabled py-0">
									<span class="dropdown-item block px-4 pt-0 py-0 fst-italic text-muted small">
										Version 2.0.1 ({{ commitHash }})
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="col d-flex flex-column h-100 p-0">
					<main
						class="row h-100 scrollable overflow-auto align-content-start w-100"
						style="margin-left:0!important;z-index: inherit;">
						<div class="p-md-5 pt-md-3 p-sm-3 p-2 xl:px-0 pb-md-0 pb-sm-0 pb-0 bg-white">
							<div class="w-100">
								<div class="form-check form-switch container-fluid small d-flex align-content-center">
									<div class="flex-grow-1" />
									<div class="ms-2">
										<label class="form-check-label" for="flexSwitchCheckDisabled">
											<span v-show="pwaStore.manuallyOnline">Online</span>
											<span v-show="!pwaStore.manuallyOnline">Working offline</span>
										</label>
										<input
											id="flexSwitchCheckDisabled" v-model="pwaStore.manuallyOnline"
											class="form-check-input" type="checkbox" role="switch"
											:disabled="!pwaStore.onlineAndConnected" @click="updatemanuallyOnline">
									</div>
									<div>
										<UiButton
											:label="''" :type="'modal'" icon="bi-info-circle"
											:data-bs-target="'#manual_offline_info_modal'" theme="small"
											:class="'p-2 mb-1'" />
									</div>
									<Modal
										:id="'manual_offline_info_modal'"
										:title="'About using the app in offline mode'" :has-form="false">
										<p>
											If you have an unstable or slow mobile data connection, you
											may wish to use the app in
											offline mode (without activating airplane mode on your
											device).
											Remember to turn the app online again when you reach
											somewhere with a stable connection
											in order to sync your data with the server and view your
											surveys from your desktop or laptop
											browser.
										</p>
									</Modal>
								</div>

								<Alert class="mb-2 grey-bg container-fluid" />
							</div>
						</div>
						<hr>
						<router-view />
					</main>

					<div class="d-block d-sm-none" style="height:120px" />
					<footer
						class="footer d-block d-sm-none mt-auto position-sticky w-100 fixed-bottom bg-theme "
						style="height:120">
						<nav id="myNavbar" class="navbar  w-100" role="tablist">
							<router-link
								to="/panel/dashboard"
								class="nav-link  my-nav-link nav-bottom-button bg-theme w-25 py-3">
								<i class="bi-speedometer2" />
								<span class="d-block d-sm-block">{{ trans('global.pages.home') }}</span>
							</router-link>
							<router-link
								to="/panel/projects"
								class="nav-link  my-nav-link nav-bottom-button bg-theme w-25 py-3">
								<i class="bi-grid-3x3-gap-fill" />
								<span class="d-block d-sm-block">{{ authStore?.user?.type == "owner" ?
									trans("global.pages.sites") : trans("global.pages.clients") }}</span>
							</router-link>
							<router-link to="/help" class="nav-link  my-nav-link nav-bottom-button bg-theme w-25 py-3">
								<i class="bi-patch-question" />
								<span class="d-block d-sm-block">{{ trans('global.pages.help') }}</span>
							</router-link>
							<router-link to="/about" class="nav-link  my-nav-link nav-bottom-button bg-theme w-25 py-3">
								<i class="bi-info-circle" />
								<span class="d-block d-sm-block">{{ trans('global.pages.about') }}</span>
							</router-link>
						</nav>
					</footer>
				</div>
			</div>
		</div>
	</div>
	<template v-else>
		<router-view />
	</template>
</template>

<script lang="ts" setup>
	import { computed, onBeforeMount, reactive, watch } from "vue";
	import Alert from "@/views/components/Alert.vue";

	import { trans } from "@/helpers/i18n";
	import WcaMenu from "@/views/layouts/WcaMenu.vue";
	import AvatarIcon from "@/views/components/icons/Avatar.vue";
	import { useAuthStore } from "@/stores/auth";
	import { useGlobalStateStore } from "@/stores/global";
	import { useRoute } from "vue-router";
	import { useAlertStore } from "@/stores/alert";
	import { getAbilitiesForRoute } from "@/helpers/routing";
	import PwaStatus from "./Components/PwaStatus.vue";
	import { usePwaStore } from "@/stores/pwa";
	import ModalSyncError from "./views/components/ModalSyncError.vue";
	import router from "./router";

	import UiButton from "@/views/components/input/UiButton.vue";
	import Modal from "@/views/components/Modal.vue";

	const alertStore = useAlertStore();
	const commitHash = process.env.VITE_COMMIT_HASH;
	const appEdition = import.meta.env.VITE_APP_EDITION;
	console.log("Current Hash:", process.env.VITE_COMMIT_HASH);
	console.log("Edition:", import.meta.env.VITE_APP_EDITION);

	const authStore = useAuthStore();
	const globalStateStore = useGlobalStateStore();
	const route = useRoute();
	const pwaStore = usePwaStore();

	const deleteDate = computed(() => {
		const date = new Date(Date.parse(authStore.user.created_at));

		// Increment the month
		date.setMonth(date.getMonth() + 3);

		// Extract year, month, and day
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		// Months are zero-based in JavaScript
		const day = date.getDate();

		return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
	});

	if (authStore.user && (appEdition == "TRAINING" || appEdition == "DEVELOPMENT" || appEdition == "STAGING"))
		alertStore.warn("You are using the " + appEdition + " app." + (appEdition == "TRAINING" ?  " Your data will be deleted on " + deleteDate.value + "." : ""));

	const isLoading = computed(() => {
		var value = false;
		for (var i in globalStateStore.loadingElements) {
			if (i) {
				value = true;
				break;
			}
		}
		return value || globalStateStore.isUILoading;
	});

	const state = reactive({
		mainMenu: [
			{
				name: trans("global.pages.home"),
				icon: "speedometer2",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false,
				to: "/panel/dashboard"
			},
			{
				name: "ACT_TYPE",
				icon: "grid-3x3-gap-fill",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false,
				to: "/panel/projects"
			},
			{
				name: trans("global.pages.help"),
				icon: "patch-question",
				to: "/help",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false
			},
			{
				name: trans("global.pages.about"),
				icon: "info-circle",
				to: "/about",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false
			}
		],
		isAccountDropdownOpen: false,
		isMobileMenuOpen: false,
		currentExpandedMenuItem: null,
		app: (window as any).AppConfig
	});

	function onLogout () {
		authStore.logout();
	}

	function install () {
		if (pwaStore.event) {
			pwaStore.event.prompt();
		}
	}

	async function sync () {
		authStore.syncCurrentUser(false).then(() =>{
			const currentRoute = router.currentRoute.value;
			console.warn(currentRoute.fullPath);
			// router.go(0);
			// router.push("/refresh");
			router.push(currentRoute.fullPath).then(() => {
				return console.log("done");
			}).catch((error) => console.error(error));
		});

	}

	onBeforeMount(() => {
		if (Object.prototype.hasOwnProperty.call(route.query, "verified") && route.query.verified) {
			alertStore.success(trans("global.phrases.email_verified"));
		}
	});

	// @todo
	// Temporary handler for the alertStore messages to capture errors raised in the store / services
	// We probably want it somewhere else eventually...
	// watch(
	// 	() => {
	// 		return alertStore.messages;
	// 	},
	// 	(newMsgs: string[]) => {
	// 		if (newMsgs.length > 0 && alertStore.type === "error") {
	// 			alert("Errors raised in alert store: \n" + alertStore.messages.map((m) => "- " + m).join("\n"));
	// 		}
	// 	}
	// );

	// We're using userSync as the error recovery system, so let's check for syncing
	// every second, then if an error does occur, we will recover quickly. The
	// browser will be fine with this level of polling
	const seconds = 1;
	let syncing = false;
	const intervalID = setInterval(async () => {
		if (pwaStore.requireSync && pwaStore.manuallyOnline) {
			if (syncing) {
				console.log("User sync already in progress, returning");
				return;
			}
			if (pwaStore.onlineAndConnected) {
				syncing = true;
				// if (authStore.checkSession()) {
				await authStore.syncCurrentUser();
				// 	console.log(authStore.checkSession() + " User sync completed");
				// } else console.log(authStore.checkSession() + " User sync skipped");
				syncing = false;
			}
		}
	}, seconds * 1000);
	// const intervalID2 = setInterval(async () => {
	// 	if (authStore.user == null)
	// 		return;
	// 	if (pwaStore.manuallyOnline) {
	// 		if (syncing) {
	// 			console.log("(15s) User sync already in progress, returning");
	// 			return;
	// 		}
	// 		if (pwaStore.onlineAndConnected) {
	// 			syncing = true;
	// 			await authStore.syncCurrentUser();
	// 			syncing = false;
	// 			console.log("(15s) User sync completed");
	// 		}
	// 	}
	// }, 15 * seconds * 1000);

	watch(() => pwaStore.requireSync, (newVal, oldVal) => {
		if (newVal) console.log("User sync required");
		else console.log("User sync not required");
		if (!(pwaStore.onlineAndConnected && pwaStore.manuallyOnline)) console.log("Couldn't sync: offline");
	});
	watch(() => pwaStore.manuallyOnline, (newVal, oldVal) => {
		if (newVal) {
			console.log("User enabled sync");
			pwaStore.requireSync = true;
		}
		else console.log("User disabled sync");
		if (!newVal) console.log("Couldn't sync: user disabled sync");
	});

	// const manuallyOnline = computed(() => { return !pwaStore.manuallyOffline; });
	function updatemanuallyOnline () {
		pwaStore.manuallyOnline = !pwaStore.manuallyOnline;
	}
</script>

<style>

/* this is needed to make the content scrollable on larger screens */
/* Stops the WCA pop up menu from dropping to the bottom of the screen */
@media (min-width: 576px) {
    /* .sm-bg-theme-800{
		--bs-bg-opacity: 1;
		background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity));
	} */
	/* width of pop up menu name (user name) */
    .h-sm-100 {
        height: 100%;
    }
    .w-sm-100 {
		width: 100%!important;
	}
}

.bg-theme {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity))!important;
}
.hover-bg-theme {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity))!important;
}

.bg-theme-600 {
    --bs-bg-opacity: 0.6;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity))!important;
}
.tobs5-bg-theme-600 {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity));
    /* background-color: rgba(13 148 136 / var(--bs-bg-opacity)); */
}
.bg-theme-800 {
    --bs-bg-opacity: 0.8;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity));
}
.hover-bg-theme-800:hover {
    --bs-bg-opacity: 0.8;
    background-color: rgba(var(--wca-menu-background), var(--bs-bg-opacity));
}
.my-p-0 {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.vh-100 {
    height: 100vh!important;
}
.vh-80 {
    height: 80vh!important;
}

/* Change the fill color of the icon to green */
.score-3 {
  color: rgb(var(--bs-success-rgb));
}
.score-2 {
    color: rgb(var(--bs-warning-rgb));
}
.score-1 {
    color: rgb(var(--bs-danger-rgb));
}
.score-0 {
    color: transparent;
}
.badge-3 {
	background-color: rgb(var(--bs-success-rgb));
  --bs-bg-opacity: 1;
}
.badge-2 {
    background-color: rgb(var(--bs-warning-rgb));
	--bs-bg-opacity: 1;
}
.badge-1 {
    background-color: rgb(var(--bs-danger-rgb));
	--bs-bg-opacity: 1;
}
.badge-0 {
    background-color: transparent;
	--bs-bg-opacity: 1;
}
.online {
	color: rgb(var(--bs-success-rgb));
}
.offline {
	color: rgb(var(--bs-danger-rgb));
}

*, ::after, ::before {
    box-sizing: border-box;
/* }

*, ::before, ::after { */
    --bs-border-spacing-x: 0;
    --bs-border-spacing-y: 0;
    --bs-translate-x: 0;
    --bs-translate-y: 0;
    --bs-rotate: 0;
    --bs-skew-x: 0;
    --bs-skew-y: 0;
    --bs-scale-x: 1;
    --bs-scale-y: 1;
    --bs-pan-x: ;
    --bs-pan-y: ;
    --bs-pinch-zoom: ;
    --bs-scroll-snap-strictness: proximity;
    --bs-gradient-from-position: ;
    --bs-gradient-via-position: ;
    --bs-gradient-to-position: ;
    --bs-ordinal: ;
    --bs-slashed-zero: ;
    --bs-numeric-figure: ;
    --bs-numeric-spacing: ;
    --bs-numeric-fraction: ;
    --bs-ring-inset: ;
    --bs-ring-offset-width: 0px;
    --bs-ring-offset-color: #fff;
    --bs-ring-color: rgb(59 130 246 / 0.5);
    --bs-ring-offset-shadow: 0 0 #0000;
    --bs-ring-shadow: 0 0 #0000;
    --bs-shadow: 0 0 #0000;
    --bs-shadow-colored: 0 0 #0000;
    --bs-blur: ;
    --bs-brightness: ;
    --bs-contrast: ;
    --bs-grayscale: ;
    --bs-hue-rotate: ;
    --bs-invert: ;
    --bs-saturate: ;
    --bs-sepia: ;
    --bs-drop-shadow: ;
    --bs-backdrop-blur: ;
    --bs-backdrop-brightness: ;
    --bs-backdrop-contrast: ;
    --bs-backdrop-grayscale: ;
    --bs-backdrop-hue-rotate: ;
    --bs-backdrop-invert: ;
    --bs-backdrop-opacity: ;
    --bs-backdrop-saturate: ;
    --bs-backdrop-sepia: ;

	--fsc-tree-green: 0,130,100;
	--fsc-grass-green: 58,165,136;
	--fsc-ocean-blue: 71, 148, 214;
	--fsc-historic-blue: 64, 74, 126;
	--fsc-natural-brown: 125, 99, 87;
	--fsc-sandstone: 227, 219, 202;
	--fsc-viola-purple: 150,92,153;
	--fsc-coral-red: 230, 104, 98;
	--fsc-warm-orange: 216, 120, 79;
	--fsc-sunshine-yellow: 251, 197, 96;
	--fsc-deep-purple: 34, 19, 44;
	--fsc-forest-green: 14, 35, 28;
	--fsc-night-sky-blue: 9,13,35;
	--fsc-woodland-brown: 42,24,4;
	--fsc-autumn-red: 73,25,24;
	--fsc-sandstone-light: 255,255,255;
	/* --fsc-:; */

    --bs-teal: 17, 94, 89 ;
	--bs-grey: 156, 163, 175;
	--bs-black: 0,0,0;

    --wca-menu-background: var(--fsc-tree-green);
	--wca-selected-card: var(--bs-white);
	--wca-deselected-card: var(--fsc-sandstone-light);
	--wca-background: var(--fsc-sandstone-light);

	--bs-nav-link-color: rgb(var(--fsc-ocean-blue));
	--bs-nav-link-hover-color: rgb(var(--historic-blue));
	--bs-link-color-rgb: var(--fsc-ocean-blue);
	--bs-link-hover-color: rgb(var(--historic-blue));

	--bs-warning-rgb: var(--fsc-sunshine-yellow);
	--bs-primary-rgb: var(--fsc-grass-blue);
	--bs-info-rgb: var(--fsc-ocean-blue);
	--bs-danger-rgb: var(--fsc-coral-red);
	--bs-success-rgb: var(--fsc-grass-green);

	--bs-dropdown-link-active-color: white;
	--bs-dropdown-link-active-bg: rgb(var(--fsc-grass-green));
	--bs-heading-color: rgb(var(--bs-secondary-color));

	--bs-body-bg: #fff;
	--primary: rgb(var(--fsc-grass-green));
	/* --secondary: #6c757d; */
	/* --success: #28a745; */
	--info: var(--fsc-ocean-blue);
	--warning: rgb(var(--fsc-sunshine-yellow));
	/* --danger: #dc3545; */

    --bs-nav-pills-link-active-bg: rgba(var(--fsc-grass-green), var(--bs-bg-opacity));
}
.btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #fbc560!important;
    --bs-btn-border-color: #fbc560!important;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fbcb70!important;
    --bs-btn-hover-border-color: #fbcb70!important;
    --bs-btn-focus-shadow-rgb: 201,158,7!important;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fcd180!important;
    --bs-btn-active-border-color: #fbcb70!important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #fbc560!important;
    --bs-btn-disabled-border-color: #fbc560!important;
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
}
  body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  }

  #myNavbar {
	overflow: hidden;
	/* background-color: #011d4c;   */
	/* position: fixed; */
	bottom: 0;
	/* width: 100%; */
	padding:0px;
	/* z-index: 100; */
  }

  #myNavbar a {
  float: left;
  display: block;
  color: #fff;
  text-align: center;
  /* padding: 14px 16px; */
  text-decoration: none;
  /* font-size: 17px; */
  }

  #myNavbar a:hover {
  background-color: #fff;
  color: rgb(var(--fsc-sandstone));
  }

  #myNavbar a.active {
  background-color: #fff;
  color: rgb(var(--fsc-sandstone));

  }

  #myNavbar .icon {
  display: none;
  }

  .my-nav-link{
	/* background:#011d4c; */
	color:white!important;
	height:100%!important;
  }

  .my-nav-link.active {
	color: #011d4c!important;
	background-color: white!important;

  }
  .my-nav-link-inv{
	background:rgb(var(--fsc-grass-green))!important;
	color:white!important;
	height:100%!important;
  }

  .my-nav-link-inv.active {
	color: #011d4c!important;
	background-color: white!important;

  }

  /* @media (max-width: 800px) { */
	.sd-imagepicker{
		align-items: center!important;
	}

	.sd-imagepicker *{
		align-items: center!important;
		white-space: normal!important;
		align-self: baseline!important;

	/* } */
}

	.sd-imagepicker__item-decorator{
		text-align:center!important;
		width: 200px!important;

	}
	.sd-imagepicker--column{
		flex-direction: row!important;
	}

	/* AUTH */
.bgrg {
    background-size: cover;
}

.bg-theme-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--wca-background), var(--tw-bg-opacity));
}
.bg-theme-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(var(--wca-background), var(--tw-bg-opacity));
}
.min-h-screen {
    min-height: 100vh;
}
.rounded-2xl {
    border-radius: 1rem;
}
.max-w-5xl {
    max-width: 64rem;
}
@media (min-width: 768px)
{.md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
}}

.my-border {
	--bs-nav-pills-border-radius: 0!important;
}

.sd-element__title.sd-element__title--disabled {
    opacity: .5!important;
}
.sd-item--disabled .sd-item__control-label {
    opacity: .5!important;
}
.sd-body.sd-body--responsive .sd-body__timer, .sd-body.sd-body--responsive .sd-body__navigation {
    padding: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) 0!important;
}

.accordion-button:hover {
  background-color: rgba(var(--wca-menu-background),0.6)!important;
}
.accordion-button {
  background-color: rgba(var(--wca-background),0.1)!important;
  background: rgba(var(--wca-background),0.1)!important;
  transition: color 0.3s ease-in-out, background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease!important;

}
 .accordion-button:not(.collapsed) {
    background-color: rgba(var(--wca-menu-background),0.8)!important;
	color: #fff!important;
	transition: fade;
}
.accordion-button:not(.collapsed):hover {
	background-color: rgba(var(--wca-menu-background),1)!important;
}

.btn-theme{
    --bs-btn-color: #fff!important;
    --bs-btn-bg: rgba(var(--wca-menu-background),0.8)!important;
    --bs-btn-border-color: rgba(var(--wca-menu-background),0.8)!important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(var(--wca-menu-background),1)!important;
    --bs-btn-hover-border-color: rgba(var(--wca-menu-background),1)!important;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgba(var(--wca-menu-background),1)!important;
    --bs-btn-active-border-color: rgba(var(--wca-menu-background),1)!important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;

    /* --bg-opacity: 0.6;
	background-color:rgba(var(--wca-menu-background),var(--bg-opacity)); */
}
/* .btn-theme:hover{
    --bg-opacity: 1;
	background-color:rgba(var(--wca-menu-background),var(--bg-opacity));
} */
 .dropdown-item {
	padding-right:0px!important;
	padding-left:0px!important;
 }
 .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    /* width: 100%; */
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important;
    /* margin-right: auto; */
    /* margin-left: auto; */
 }
 html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* .modal-backdrop {
    display:none;
} */
</style>
