<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<Page v-if="estate && estateId == estate.id" :icon="'bi-map'" :title="estate.name || 'Unnamed (please edit property details to add name)'" :back="page.back" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<!-- <div class="  d-flex justify-content-between">
					<div>
						<h4><i class="bi-map me-2"/>{{ estate.name || "Unnamed (please edit estate details to add name)" }}</h4>
					</div>
					<div>
						<button class="btn  " type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="bi-three-dots"/>
						</button>
						<ul class="dropdown-menu p-3">
							<li class="m-2" type="button" @click.prevent="deleteEstate()">
								Delete Estate<i class="bi-trash ms-2"/>
							</li>
							<li class="m-2" type="button" data-bs-toggle="modal" :data-bs-target="'#updateEstateModal_'+estate.id">
								Edit Details<i class="bi-pencil ms-2"/>
							</li>
						</ul>
					</div> -->
				<ModalEstateUpdate :element-id="'updateEstateModal_'+estate.id" :estate="estate"/>
				<!-- </div> -->

				<p class="px-3">
					You have {{ estate.sites.length }} sites for this property.
				</p>

				<div v-if="estate.sites" class="row">
					<CardSite
						v-for="(site,index) in estate.sites.sort(createCompareFn('name', 'asc'))" :key="index"
						:site="site"
						:estate-id="estate.id"
						:client-id="clientId"
					/>

					<!-- Button trigger modal: ADD SITE -->
					<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
						<div class="card">
							<div
								class="card-body text-center" type="button" data-bs-toggle="modal" :data-bs-target="'#addSiteModal_'+estate.id">
								<div class="media">
									<div class="media-body text-left">
										<h3 ><i class="bi-plus-square" style="font-size: larger;"/></h3>
									</div>
								</div>
							</div>
							<div class="card-footer text-center">
								Add Woodland Site
							</div>
						</div>
						<ModalSiteAdd :estate="estate" :element-id="'addSiteModal_'+estate.id"/>
					</div>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import router from "@/router";

	import { defineComponent, computed, onMounted, ref, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import axios from "@/plugins/axios";
	import ModalSiteUpdate from "@/views/pages/private/dashboard/partials/ModalSiteUpdate.vue";
	import ModalEstateUpdate from "@/views/pages/private/dashboard/partials/ModalEstateUpdate.vue";
	import ModalSiteAdd from "@/views/pages/private/dashboard/partials/ModalSiteAdd.vue";

	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { v4 as uuidv4 } from "uuid";

	import CardSite from "@/views/pages/private/dashboard/partials/CardSite.vue";
	import { createCompareFn } from "@/modules/utils";
	import { useEstateStore } from "@/stores/estate";
	import type { Estate } from "@/types/estate";
	import type { Client } from "@/types/client";

	const props = defineProps<{
		estateId: string
		clientId: string
	}>();

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const estateStore = useEstateStore();

	const client = computed<Client | undefined>(() => {
		if (!authStore.user) return undefined;
		return authStore.user.clients.find((x: any) => x.id == props.clientId);
	});
	const estate = computed<Estate | undefined>(() => {
		if (!client.value) return undefined;
		// weirdly the client_id wasnt being found and brought through to site add...
		let X = client.value.estates.find((x: any) => x.id == props.estateId);
		if (X) X.client_id = client.value.id;
		return X;
	});

	const page = reactive({
		id: "create_users",
		title: trans("global.pages.users_create"),
		back: "/client/" + client.value?.id,
		filters: false,
		breadcrumbs: [
			{
				name: client.value?.name,
				to: "/client/" + client.value?.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.value ? estate.value.name : "No estate found",
				active: true,
				icon: "bi-map"
			}
		],
		actions: [
			{
				id: "delete",
				name: "Delete Property",
				icon: "bi-trash",
				theme: "none",
				type: "slim-button"
			},
			{
				id: "update",
				name: "Edit Details",
				icon: "bi-pencil",
				theme: "none",
				type: "modal",
				data_bs_target: "#updateEstateModal_" + (estate.value ? estate.value.id : "")
			}
		]
	});
	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			deleteEstate();
			break;
		}
	}

	async function deleteEstate () {
		if (!estate.value) throw new Error("EstateInfo.vue deleteEstate does not have an estate to delete");
		if (!client.value) throw new Error("EstateInfo.vue deleteEstate does not have a relevant client");
		if (confirm("Are you sure you want to to delete this estate? All linked surveys will be deleted as well.")) {
			estate.value.client_id = client.value.id;
			try {
				await estateStore.destroy(estate.value);
			} finally {
				router.push("/client/" + client.value.id);
			}
		}
	}
	</script>
